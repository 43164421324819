import React from "react";

const ProductDetails = ({ cardData }) => {
  const {
    manufactureTitle,
    manufacture,
    manufactureFrom,
    returnPolicyTitle,
    returnPolicy,
    specialityTitle,
    specialityDesc1,
    specialityDesc2,
  } = cardData?.productDetails;
  return (
    <div className="md:w-1/2 md:px-5 flex flex-col lg:gap-5 gap-3 mx-auto">
      <div>
        <h2 className="lg:text-xl text-base font-semibold">
          {cardData?.content}
        </h2>
        <h2 className="font-semibold md:pt-5 ">
          ₹ {cardData?.oldPrice ? cardData?.oldPrice : cardData?.newPrice}
        </h2>
      </div>
      <div className="flex flex-col gap-5 md:text-base text-sm">
        <div className="text-md">
          <h2 className="font-semibold">{manufactureTitle}</h2>
          <p className="text-mediumGray uppercase">{manufacture}</p>
          {manufactureFrom && (
            <p className="text-sm text-mediumGray">{manufactureFrom}</p>
          )}
        </div>

        <div className="text-md">
          <h2 className="font-semibold">{returnPolicyTitle}</h2>
          <p className="text-mediumGray">{returnPolicy}</p>
        </div>
        {specialityTitle && (
          <div className="text-md">
            <h2 className="font-semibold">{specialityTitle}</h2>
            <p className="text-mediumGray py-1">{specialityDesc1}</p>
            <p className="text-mediumGray">{specialityDesc2}</p>
          </div>
        )}

        {/* <div className="text-md">
      <h2 className="font-semibold">{expireTitle}</h2>
      <p className="text-mediumGray">{expire}</p>
    </div> */}
      </div>
    </div>
  );
};

export default ProductDetails;
