import { useState } from "react";
import { Link } from "react-router-dom";
import ProductItem from "./ProductItem";

const ProductItemList = ({ filterData, quickView }) => {
  // console.log(filterData);
  const [buttonActivate, setButtonActivate] = useState(false);

  const [visible, setVisible] = useState(false);

  return (
    <div
      onMouseOver={() => setVisible(true)}
      onMouseOut={() => setVisible(false)}
      className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 justify-items-center gap-y-3 "
    >
      {filterData.map((data) => {
        // console.log(data);

        return (
          <Link key={data?.id} to={`/shop/${data?.content}/${data?.id}`}>
            <ProductItem product={data} quickView={quickView} />
          </Link>
        );
      })}
    </div>
  );
};

export default ProductItemList;
