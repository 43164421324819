import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Image } from "antd";

const ProductImage = ({ cardData }) => {
  const [activeImg, setActiveImg] = useState(cardData?.image_Url);

  return (
    // <div className="flex w-1/3 gap-10 items-center justify-center">
    //   <div className="flex flex-col gap-3 items-center">
    //     {cardData?.smallImages_Url.length > 4 && (
    //       <button className="border border-mediumBlue text-mediumBlue p-1 rounded">
    //         <IoIosArrowUp size={20} />
    //       </button>
    //     )}
    //     <div
    //       className={`flex flex-col h-80 gap-3 overflow-hidden  ${
    //         cardData?.smallImages_Url.length > 4
    //           ? "justify-start"
    //           : "justify-center"
    //       }`}
    //     >
    //       {cardData?.smallImages_Url.map((url) => {
    //         const { smallImageId, smallImageUrl } = url;

    //         return (
    //           <img
    //             key={smallImageId}
    //             src={smallImageUrl}
    //             alt={smallImageUrl}
    //             className={`w-16 h-16 object-contain border border-lightGray hover:border-mediumBlue duration-500 rounded p-1`}
    //             onClick={() => setActiveImg(smallImageUrl)}
    //           />
    //         );
    //       })}
    //     </div>
    //     {cardData?.smallImages_Url.length > 4 && (
    //       <button className="border border-mediumBlue text-mediumBlue p-1 rounded">
    //         <IoIosArrowDown size={20} />
    //       </button>
    //     )}
    //   </div>

    //   <div className="w-72 object-cover">
    //     <Image width={280} src={activeImg} />
    //   </div>
    // </div>

    <div className="flex flex-col-reverse md:flex-row md:w-1/2 md:gap-10 gap-5 items-center justify-center mx-auto md:mx-0">
      <div className="flex flex-col gap-3 items-center">
        {cardData?.smallImages_Url.length > 4 && (
          <button className="border border-mediumBlue text-mediumBlue p-1 rounded">
            <IoIosArrowUp size={20} />
          </button>
        )}
        <div
          className={`flex flex-row md:flex-col md:h-80 gap-3 overflow-hidden  ${
            cardData?.smallImages_Url.length > 4
              ? "justify-start"
              : "justify-center"
          }`}
        >
          {cardData?.smallImages_Url.map((url) => {
            const { smallImageId, smallImageUrl } = url;

            return (
              <img
                key={smallImageId}
                src={smallImageUrl}
                alt={smallImageUrl}
                className={`w-16 h-16 object-contain border border-lightGray hover:border-mediumBlue duration-500 rounded p-1`}
                onClick={() => setActiveImg(smallImageUrl)}
              />
            );
          })}
        </div>
        {cardData?.smallImages_Url.length > 4 && (
          <button className="border border-mediumBlue text-mediumBlue p-1 rounded">
            <IoIosArrowDown size={20} />
          </button>
        )}
      </div>

      <div className=" border border-lightGray rounded object-cover">
        <div className="hidden md:block">
          <Image width={400} src={activeImg} />
        </div>
        <img src={activeImg} className="block md:hidden w-60" />
      </div>
    </div>
  );
};

export default ProductImage;
