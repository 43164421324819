import "./App.css";
import Shop from "./Components/Pages/Shop/Shop";
import { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Error from "./Components/utils/Error";
import "./Components/i18next";
import ItemCard from "./Components/Pages/Shop/ItemCard";
import { CartProvider } from "./Components/Pages/Shop/CartContext";
import { useTranslation } from "react-i18next";
import Cart from "./Components/Pages/Shop/Cart";
import ShopLayout from "./Components/Pages/Shop/Documents/ShopLayout";
import ShopShipping from "./Components/Pages/Shop/Documents/ShopShipping";
import ShopCancel from "./Components/Pages/Shop/Documents/ShopCancel";
import ShopTerms from "./Components/Pages/Shop/Documents/ShopTerms";

// Context Provider

export const ContextProvider = createContext();

function App() {
  const [shimmerEffect, setShimmerEffect] = useState(true);
  const [loader, setLoader] = useState(true);
  const [quantity, setQuantity] = useState(0);

  const { t } = useTranslation();
  const { shopData } = t("shop");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoader(false);
    }, 4000);

    // Cleanup
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShimmerEffect(false);
      setLoader(true);
    }, 1500);

    return () => clearTimeout(timeId);
  }, []);

  return (
    <CartProvider>
      <ContextProvider.Provider
        value={{
          shimmerEffect,
          setShimmerEffect,
          loader,
          setLoader,
          quantity,
          setQuantity,
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Error />} />

            {/****************    Shop Page     ****************/}

            <Route
              path="/"
              element={<ShopLayout children={<Shop products={shopData} />} />}
            ></Route>
            <Route
              path="/shop/:content/:id"
              element={
                <ShopLayout children={<ItemCard products={shopData} />} />
              }
            ></Route>
            <Route
              path="/shop/terms"
              element={<ShopLayout children={<ShopTerms />} />}
            ></Route>
            <Route
              path="/shop/cancel"
              element={<ShopLayout children={<ShopCancel />} />}
            ></Route>
            <Route
              path="/shop/shipping"
              element={<ShopLayout children={<ShopShipping />} />}
            ></Route>

            {/****************    Cart     ****************/}

            <Route
              path="/cart"
              element={<ShopLayout children={<Cart />} />}
            ></Route>
          </Routes>
        </BrowserRouter>
      </ContextProvider.Provider>
    </CartProvider>
  );
}
export default App;
