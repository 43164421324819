import { Link } from "react-router-dom";
import { scrollToTop } from "../../../utils/constant";
import MetaInfo from "../../../Meta/Meta-Info";

const ShopTerms = () => {
  return (
    <section className="border-[1rem] sm:border-[3rem] border-lightBlue p-4 sm:p-8 flex flex-col gap-10  md:text-base text-sm">
      {/* Meta PageTitle and PageDescription */}

      <MetaInfo
        pageTitle={" NDHealth Terms of Service: Read Before You Proceed"}
        pageDescription={
          "Get familiar with our Terms of Service before using ND Health. Learn about our policies and understand your rights and obligations as a user."
        }
        pageKeywords={
          ("NDHealth Terms of Service",
          "NDHealth Terms and Conditions",
          "NDHealth Privacy Policy",
          "NDHealth User Agreement",
          "NDHealth Legal")
        }
      />

      <h1 className="lg:text-5xl md:text-2xl text-xl font-medium text-center sm:py-5 font-poppins">
        TERMS & CONDITIONS
      </h1>
      <ul className="flex flex-col gap-7 md:text-base text-sm">
        {/*************  1.  Use of Platform    *********************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">1. Use of Platform</h3>
            <p className="md:leading-7">
              Welcome to{" "}
              <Link
                className="text-primary hover:underline"
                to={"www.ndhealth.ai"}
              >
                {" "}
                www.ndhealth.ai{" "}
              </Link>{" "}
              ( "Site" or "NDHealth " ). The websites{" "}
              <Link
                className="text-primary hover:underline"
                to={"www.ndhealth.ai"}
              >
                {" "}
                www.ndhealth.ai{" "}
              </Link>{" "}
              & www.ndhealth.shop are owned and operated by J & B CO, An
              Registered Partnership firm with its registered office at 59,
              Brindavan Colony , AS Rao Nagar, Secunderabad, Telangana India.
              Ndhealth.ai & Ndhealth.shop are together referred to as Nd Health
              and the Site and retail stores are together referred as Platform.
              You may be accessing our Site from a computer or mobile phone
              device (through an iOS or Android application, for example) and
              these Terms of Use govern your use of our Site and your conduct,
              regardless of the means of access. You may also be using our
              interactive services <b>(" Interactive Services ")</b> , such as
              our Health and Wellness Suggestions, Diet Book, Anti aging guide
              etc. and the Wellness Services wherein you can book any care &
              wellness service provided by our partners (" Wellness Services ")
              and these Terms of Use also govern your use of the Interactive
              platform and Wellness Services. These Terms of Use govern all the
              products offered on the Platform including the Interactive as well
              as
              <b> Wellness Services.</b> The Platform is only to be used for
              your personal non-commercial use and information. Your use of the
              services and features of the Platform shall be governed by these
              Terms and Conditions (hereinafter " Terms of Use ") along with the
              Privacy Policy, Shipping Policy and Cancellation, Refund and
              Return Policy (together " Policies ") as modified and amended from
              time to time. By mere accessing or using the Platform, you are
              acknowledging, without limitation or qualification, to be bound by
              these Terms of Use and the Polices, whether you have read the same
              or not.
              <b>
                {" "}
                ACCESSING, BROWSING OR OTHERWISE USING THE PLATFORM INDICATES
                YOUR UNCONDITIONAL AGREEMENT TO ALL THE TERMS AND CONDITIONS IN
                THIS AGREEMENT, SO PLEASE READ THIS AGREEMENT CAREFULLY BEFORE
                PROCEEDING .
              </b>{" "}
              If you do not agree to any of the terms enumerated in the Terms of
              Use or the Policies, please do not use the Platform. You are
              responsible to ensure that your access to this Platform and
              material available on or through it are legal in each
              jurisdiction, in or through which you access or view the platform
              or such material. Nd Health reserves the unilateral right to
              change the particulars contained in the Terms of Use or the
              Policies from time to time and at any time, without notice to its
              users and in its sole discretion. If Nd Health decides to change
              the Terms of Use or Policies, NdHealth will post the new version
              of the Terms of Use or the Policies on the Site and update the
              date specified above. Any change or modification to the Terms of
              Use and the Policies will be effective immediately from the date
              of such upload of the Terms of Use and Policies on the Site. Your
              continued use of the Platform following the modifications to the
              Terms of Use and Policies constitutes your acceptance of the
              modified Terms of Use and Policies whether or not you have read
              them. For this reason, you should frequently review these Terms of
              Use, our Guidelines and Rules and any other applicable policies,
              including their dates, to understand the terms and conditions that
              apply to your use of the Site.
            </p>
          </div>
        </li>

        {/************* 2.   Privacy Practices    *********************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">2. Privacy Practices</h3>
            <p className="md:leading-7">
              We understand the importance of safeguarding your personal
              information and we have formulated a Privacy Policy, to ensure
              that your personal information is sufficiently protected. Apart
              from these Terms of Use, the Privacy Policy shall also govern your
              visit and use of the Site. Your continued use of the Site implies
              that you have read and accepted the Privacy Policy and agree to be
              bound by its terms and conditions. You consent to the use of
              personal information by NdHealth in accordance with the terms of
              and purposes set forth in the Privacy Policy, the same may be
              subject to amendment from time to time at the sole discretion of
              NdHealth.
            </p>
          </div>
        </li>

        {/*************   3. Your Account    *********************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">3. Your Account</h3>
            <p className="md:leading-7">
              This Site is directed to be used by adults only. We assume that
              any minor, if at all, accessing our Site is under the supervision
              of their guardians. NdHealth or its associates do not knowingly
              collect information from minors. You will be responsible for
              maintaining confidentiality of your account, password, and
              restricting access to your computer, and you hereby accept
              responsibility for all activities that occur under your account
              and password. You acknowledge that the information you provide, in
              any manner whatsoever, are not confidential or proprietary and
              does not infringe any rights of a third party in whatsoever nature
            </p>
            <p className="md:leading-7 py-5">
              If you are accessing, browsing and using the Site on someone
              else’s behalf; you represent that you have the authority to bind
              that person to all the terms and conditions herein. In the event
              that the person refuses to be bound as the principal to the Terms
              of Use, you agree to accept liability for any harm caused by any
              wrongful use of the Site resulting from such access or use of the
              Site in whatsoever nature
            </p>

            <p className="md:leading-7">
              If you know or have reasons to believe that the security of your
              account has been breached, you should contact us immediately at
              the 'Contact Information' provided below. If we have found a
              breach or suspected breach of the security of your account, we may
              require you to change your password, temporarily or permanently
              block or suspend your account without any liability to NdHealth.
            </p>

            <p className="md:leading-7 py-5">
              {" "}
              We reserve the right to refuse service and/or terminate accounts
              without prior notice if these Terms of Use are violated or if we
              decide, in our sole discretion, that it would be in NdHealth’s and
              NdHealth’s best interests to do so. You are solely responsible for
              all contents that you upload, post, email or otherwise transmit
              via the Site.The information provided to us shall be maintained by
              us in accordance with our Privacy Policy.
            </p>
          </div>
        </li>

        {/*************  4.  Product & Services Information    *********************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">
              4. Product & Services Information
            </h3>
            <p className="md:leading-7">
              NdHealth attempts to be as accurate as possible in the description
              of the product on the Platform. However, NdHealth does not warrant
              that the product description, colour, information or other content
              of the Platform is accurate, complete, reliable, current or
              error-free. The Site may contain typographical errors or
              inaccuracies and may not be complete or current. The product
              pictures are indicative and may not match the actual product.
            </p>
            <p className="md:leading-7 pt-5">
              NdHealth reserves the right to correct, change or update
              information, errors, inaccuracies or omissions at any time
              (including after an order has been submitted ) without prior
              notice. Please note that such errors, inaccuracies or omissions
              may also relate to pricing and availability of the product or
              services.
            </p>
          </div>
        </li>

        {/*************  5. Product Use & Services    *********************/}
        <li className="md:text-base text-sm">
          <div>
            <h3 className="md:text-2xl text-xl pb-5">
              5. Product Use & Services
            </h3>
            <p className="md:leading-7">
              The products and services available on the Platform, and the
              samples, if any, that Platform may provide you, are for your
              personal and/or professional use only. The products or services,
              or samples thereof, which you may receive from us, shall not be
              sold or resold for any/commercial reasons.
            </p>
            <p className="md:leading-7 pt-5">
              In case any products or wellness services purchased / received /
              availed causes side effects or doesn’t suit you, please note that
              NdHealth is in no manner responsible for any manufactural
              side-effects or service provider and manufacturer or service
              provider of the product or service shall be solely responsible for
              such side effects and consumer complaints. You should carefully
              read the individual terms and conditions in relation to the
              products and beauty services and consult a specialist before the
              use of the same
            </p>
          </div>
        </li>

        {/*************  6.  Recommendation of the Product & Services    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">
              6. Recommendation of the Product & Services
            </h3>
            <p className="md:leading-7">
              Any recommendation made to you in the Site during the course of
              your use of the Site is purely for informational purposes and for
              your convenience and does not amount to endorsement of the product
              or services by NdHealth or any of its associates in any manner.
            </p>
          </div>
        </li>

        {/************* 7.  Pricing Information    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">7. Pricing Information</h3>
            <p className="md:leading-7">
              NdHealth strives to provide accurate product and pricing
              information, however errors may occur..
            </p>
            <p className="md:leading-7 py-5">
              NdHealth cannot confirm the price of the product until you make
              the order. Without limiting the generality of Clause 8
              (Cancellations, Refunds and Returns) below, if a product / service
              is listed at an incorrect price or with incorrect information due
              to any technical error, NdHealth shall have the right, at its sole
              discretion, to refuse or cancel any orders placed for that
              product/ service, unless the product has already been delivered or
              the service has already been availed by you. In the event that an
              item is wrongly priced, NdHealth may, at its discretion, either
              contact you for instructions or cancel your order and notify you
              of such cancellation. Unless the product ordered by you has been
              delivered and the services are availed, your offer will not be
              deemed accepted and NdHealth will have the right to modify the
              price of the product/ service and contact you for further
              instructions using the e-mail address provided by you during the
              time of registration or placing of order, or cancel the order and
              notify you of such cancellation. In the event that NdHealth
              accepts your order the same shall be debited to your credit card/
              debit card account and duly notified to you by email that the
              payment has been processed. The payment may be processed prior to
              NdHealth’s dispatch of the product that you have ordered. If we
              have to cancel the order after we have processed the payment, the
              said amount will be reversed to your credit / debit card account.
            </p>
            <p className="md:leading-7">
              {" "}
              <b>
                PRICES AND AVAILABILITY OF THE PRODUCTS AND SERVICES PROVIDED OR
                OFFERRED ON THE SITE ARE SUBJECT TO CHANGE WITHOUT PRIOR NOTICE
                AND AT THE SOLE DISCRETION OF NdHealth.
              </b>{" "}
              NdHealth may revise and cease to make available any product/
              services at anytime. In the event, NdHealth is unable to deliver
              the product to you on time or at all, you will be notified by an
              e-mail and your order will be automatically cancelled due to
              unavailability of the product or at your instructions due to
              failure to deliver the product on the expected time of delivery by
              our delivery partners. NdHealth shall not be liable to pay for any
              damages in such an event owing to cancellation of the order or
              delay in delivery.
            </p>
          </div>
        </li>

        {/************* 8. Cancellations, Refunds and Returns    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">
              8. Cancellations, Refunds and Returns
            </h3>
            <p className="md:leading-7">
              Please refer to our{" "}
              <Link
                onClick={scrollToTop}
                to={"/shop/cancel"}
                className="text-primary hover:underline"
              >
                {" "}
                Cancellations , Refunds and Returns Policy{" "}
              </Link>{" "}
              provided on our Site.
            </p>
          </div>
        </li>

        {/************* 9. Mode of Payment    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">9. Mode of Payment</h3>
            <p className="md:leading-7">
              Payments for the products available on the Site may be made in the
              following ways:
            </p>
            <ul className="list-disc md:pl-3">
              <li className="md:leading-7">
                Payments can be made by Credit Cards, Debit Cards, Net Banking,
                Wallets, e-Gift cards, and reward points.
              </li>
              <li className="md:leading-7">
                Credit card, Debit Card and Net Banking payment options are
                instant payment options and recommended to ensure faster
                processing of your order.
              </li>
              <li className="md:leading-7">
                COD mode of payment is available for almost all pin codes within
                India on orders above INR 499. We do not ship internationally
                currently.
              </li>
            </ul>
          </div>
        </li>

        {/************* 10. Shipping and Delivery    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">
              10. Shipping and Delivery
            </h3>
            <p className="md:leading-7">
              Please refer to our{" "}
              <Link
                to={"/shop/shipping"}
                className="text-primary hover:underline"
              >
                {" "}
                Shipping and Delivery Policy{" "}
              </Link>
              provided on our Site, as amended from time to time.
            </p>
          </div>
        </li>

        {/************* 11. User Content    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">11. User Content</h3>
            <p className="md:leading-7">
              The information, photo, image, chat communication, text, software,
              data, music, sound, graphics, messages, videos or other materials
              transmitted, uploaded, posted, emailed or otherwise made available
              to us (" User Content "), are entirely your responsibility and we
              will not be held responsible, in any manner whatsoever, in
              connection to the User Content. You agree to not encourage or
              assist or engage others as well as yourself in transmitting,
              hosting, displaying, uploading, modifying, publishing
              transmitting, updating or sharing any information that
            </p>
            <ul className="list-decimal md:pl-3">
              <li className="md:leading-7">
                Belongs to another person and to which the user does not have
                any right to;
              </li>
              <li className="md:leading-7">
                Is grossly harmful, harassing, blasphemous defamatory, obscene,
                pornographic, paedophilic, libelous, invasive of another's
                privacy, hateful, or racially, ethnically objectionable,
                disparaging, relating or encouraging money laundering or
                gambling, trolling, propaganda or otherwise unlawful in any
                manner whatever;
              </li>
              <li className="md:leading-7">Harm minors in any way;</li>
              <li className="md:leading-7">
                Infringes any patent, trademark, copyright or other proprietary
                rights;
              </li>
              <li className="md:leading-7">
                Violates any law for the time being in force;
              </li>
              <li className="md:leading-7">
                Deceives or misleads the addressee about the origin of such
                messages or communicates any information which is grossly
                offensive or menacing in nature;{" "}
              </li>
              <li className="md:leading-7">Impersonate another person; </li>
              <li className="md:leading-7">
                Contains software viruses or any other computer code, files or
                programs designed to interrupt, destroy or limit the
                functionality of any computer resource;{" "}
              </li>
              <li className="md:leading-7">
                Threatens the unity, integrity, defence, security or sovereignty
                of India, friendly relations with foreign states, or public
                order or causes incitement to the commission of any cognizable
                offence or prevents investigation of any offence or is insulting
                any other nation.{" "}
              </li>
            </ul>

            <p className="md:leading-7 py-5">
              NdHealth shall in no way be held responsible for examining or
              evaluating User Content, nor does it assume any responsibility or
              liability for the User Content. NdHealth does not endorse or
              control the User Content transmitted or posted on the Site by you
              and therefore, accuracy, integrity or quality of User Content is
              not guaranteed by NdHealth. You understand that by using the Site,
              you may be exposed to User Content that is offensive, indecent or
              objectionable to you. Under no circumstances will NdHealth be
              liable in any way for any User Content, including without
              limitation, for any errors or omissions in any User Content, or
              for any loss or damage of any kind incurred by you as a result of
              the use of any User Content transmitted, uploaded, posted,
              e-mailed or otherwise made available via the Site. You hereby
              waive all rights to any claims against NdHealth for any alleged or
              actual infringements of any proprietary rights, rights of privacy
              and publicity, moral rights, and rights of attribution in
              connection with User Content.
            </p>

            <p className="md:leading-7 pb-5">
              You hereby acknowledge that NdHealth has the right (but not the
              obligation) in its sole discretion to refuse to post or remove any
              User Content and further reserves the right to change, condense,
              or delete any User Content. Without limiting the generality of the
              foregoing or any other provision of these Terms and Conditions,
              NdHealth has the right to remove any User Content that violates
              these Terms and Conditions or is otherwise objectionable and
              further reserves the right to refuse service and/or terminate
              accounts without prior notice for any users who violate these
              Terms and Conditions or infringe the rights of others.
            </p>

            <p className="md:leading-7">
              If you wish to delete your User Content on our Site or in
              connection with our mobile applications, please contact us by
              email at{" "}
              <Link
                className="text-primary hover:underline"
                target="_blank"
                to={"mailto:support@ndhealth.ai"}
              >
                support@ndhealth.ai{" "}
              </Link>
              and request you to include the following personal information in
              your deletion request: first name, last name, user name/screen
              name (if applicable), email address associated with our Site
              and/or mobile applications, your reason for deleting the posting,
              and date(s) of posting(s) you wish to delete (if you have it). We
              may not be able to process your deletion request if you are unable
              to provide such information to us. Please allow up to 30 business
              days to process your deletion request.
            </p>
          </div>
        </li>

        {/************* 12. Intellectual Property Rights    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">
              12. Intellectual Property Rights
            </h3>
            <p className="md:leading-7">
              The{" "}
              <b>
                {" "}
                "NdHealth", "NdHealth & Wellness " and "New Directions Health &
                Wellness"{" "}
              </b>{" "}
              name and logo and all related product and service names, design
              marks and slogans are the trademarks, logos or service marks
              (hereinafter referred to as "Marks" ) of NdHealth. All other Marks
              provided on the Site are the property of their respective
              companies. No trademark or service mark license is granted in
              connection with the materials contained on this Site. Access to
              this Site does not authorize anyone to use any Marks in any
              manner. Marks displayed on this Site, whether registered or
              unregistered, of Nd Health or others, are the intellectual
              property of their respective owners, and NdHealth shall not be
              held liable in any manner whatsoever for any unlawful,
              unauthorized use of the Marks.
            </p>
            <p className="md:leading-7 py-5">
              NdHealth and its suppliers and licensors expressly and exclusively
              reserve all the intellectual property rights in all text,
              programs, products, processes, technology, content, software and
              other materials, which appear on this Site, including its looks
              and feel. The compilation (meaning the collection, arrangement and
              assembly ) of the content on the Site is the exclusive property of
              NdHealth and are protected by the Indian copyright laws and
              International treaties. Consequently, the materials on this Site
              shall not be copied, reproduced, duplicated, republished,
              downloaded, posted, transmitted, distributed or modified in whole
              or in part or in any other form whatsoever, except for your
              personal, non-commercial use only. No right, title or interest in
              any downloaded materials or software is transferred to you as a
              result of any such downloading or copying, reproducing,
              duplicating, republishing, posting, transmitting, distributing or
              modifying.
            </p>
            <p className="md:leading-7">
              All materials, including images, text, illustrations, designs,
              icons, photographs, programs, music clips, downloads, video clips
              and written and other materials that are part of this Site
              (collectively, the "Contents " ) are intended solely for personal,
              non-commercial use. You may download or copy the Contents and
              other downloadable materials displayed on the Site for your
              personal use only. We also grant you a limited, revocable,
              non-transferable, and non-exclusive license to create a hyperlink
              to the home page of the Site for personal, non-commercial use
              only. The Contents and software on this Site are to be used only
              as a shopping resource. Any other use, including the reproduction,
              modification, distribution, transmission, re-publication, display,
              or performance, of the Contents on this Site is strictly
              prohibited. Unless NdHealth explicitly provides to the contrary,
              all Contents are copyrighted, trademarked, trade dressed and/or
              other intellectual property owned, controlled or licensed by
              NdHealth, any of its affiliates or by third parties who have
              licensed their materials to NdHealth and are protected by Indian
              copyright laws and international treaties.
            </p>
          </div>
        </li>

        {/************* 13. Limited License    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">13. Limited License</h3>
            <p className="md:leading-7">
              NdHealth grants you limited, non-exclusive, non-transferable,
              non-sublicensable license to access, and make personal and non
              commercial use of the Site. All rights not expressly granted to
              you in these Terms of Use, are reserved and retained by the Site
              and its affiliates.
            </p>
            <p className="md:leading-7 py-5">
              NdHealth reserves the right, at any time, without notice, and at
              its sole discretion, to terminate your license to use the Site and
              to block and prevent your future access the Site.
            </p>
          </div>
        </li>

        {/************* 14. Representations and Warranties    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">
              14. Representations and Warranties
            </h3>
            <p className="md:leading-7">
              This Site is provided to you "AS IS". We make no representations
              regarding the use of or the result of the use/depiction of the
              Contents on the Site in terms of their correctness, accuracy,
              reliability, or otherwise. NdHealth shall not be liable for any
              loss suffered in any manner by the user as a result of depending
              directly or indirectly on the depiction of the Content on this
              Site.
            </p>
            <p className="md:leading-7 py-5">
              You acknowledge that this Site is provided only on the basis set
              out in the Terms of Use. Your uninterrupted access or use of this
              Site on this basis may be prevented by certain factors outside our
              reasonable control including, without limitation, the
              unavailability, inoperability or interruption of the internet or
              other telecommunications services or as a result of any
              maintenance or other service work carried out on this Site.
            </p>
            <p className="md:leading-7 pb-5">
              NdHealth through the Site intends to provide its users with
              general information about beauty and healthcare topics and
              products. The Site encourages its users to interact with NdHealth
              and its employees by asking questions that enables the user to
              obtain information of a general nature pertaining to beauty and
              healthcare topics. The postings on the Site or on social
              networking sites, including the Site’s Facebook page, or any
              information provided over chat or e-mails exchanged with NdHealth,
              its employees or representatives (collectively referred to as "
              Information ") which are in furtherance of any communication made
              by the user with NdHealth, its employees or representatives is
              based on the background provided by the user. The Information is
              not intended to constitute personal medical suggestion or be used
              for any medical diagnosis or treatment. While NdHealth takes
              reasonable care to ensure that the Information is accurate,
              NdHealth makes no representation and takes no responsibility for
              the accuracy, completeness, appropriateness or usefulness of the
              Information. For any specific medical conditions or issues, users
              are advised to seek opinion from a certified medical practitioner.
              In the event any user relies on the Information provided by
              NdHealth or its representatives/ employees, he/she may do so at
              its own risk. Under no circumstances will NdHealth, its employees,
              representative or affiliates be liable for the Information or the
              consequences of relying on such Information. In keeping with
              NdHealth's objective of providing \its users with general
              information about beauty and healthcare topics and products,
              NdHealth engages experts having knowledge of in their respective
              fields (" Experts ") to respond to queries posted by users. Any
              information provided by the Experts, either over the Site, through
              e-mails, chat rooms or on social networking sites, including the
              Site's facebook page (collectively referred to as " Expert's
              Opinion ") is based on the Expert’s personal opinion. The Expert's
              Opinion is not intended to constitute personal medical suggestion
              or be used for any medical diagnosis or treatment. NdHealth makes
              no representation and takes no responsibility for the accuracy,
              completeness, appropriateness or usefulness of the Expert's
              Opinion. In case any user relies on such Expert's Opinion, he/she
              may do so at its own risk. Under no circumstances will the
              NdHealth, Company, its employees, representative or affiliates be
              liable for such Expert's Opinion or for the consequences of
              relying on such Expert's Opinion.
            </p>
            <p className="md:leading-7">
              For any general information posted on the Site, users must not
              infer or assume that such information necessarily applies to them.
            </p>
          </div>
        </li>

        {/************* 15. Disclaimer of Warranty and Limitations of Liability    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">
              15. Disclaimer of Warranty and Limitations of Liability
            </h3>
            <p className="md:leading-7">
              THE SITE IS PRESENTED "AS IS." NEITHER WE NOR OUR HOLDING,
              SUBSIDIARIES, AFFILIATES, PARTNERS, OR LICENSORS MAKE ANY
              REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER, EXPRESS OR
              IMPLIED, IN CONNECTION WITH THESE TERMS AND CONDITIONS OR THE SITE
              OR ANY OF THE CONTENT, INCLUDING BUT NOT LIMITED TO WARRANTIES OF
              MERCHANTABILITY, NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR
              PURPOSE, EXCEPT TO THE EXTENT SUCH REPRESENTATIONS AND WARRANTIES
              ARE NOT LEGALLY EXCLUDABLE.
            </p>
            <p className="md:leading-7 py-5">
              YOU AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
              NEITHER WE NOR OUR HOLDING, SUBSIDIARIES, AFFILIATES, PARTNERS, OR
              LICENSORS WILL BE RESPONSIBLE OR LIABLE (WHETHER IN CONTRACT, TORT
              (INCLUDING NEGLIGENCE) OR OTHERWISE) UNDER ANY CIRCUMSTANCES FOR
              ANY (a) INTERRUPTION OF BUSINESS; (b) ACCESS DELAYS OR ACCESS
              INTERRUPTIONS TO THE SITE; (c) DATA NON-DELIVERY, LOSS, THEFT,
              MISDELIVERY, CORRUPTION, DESTRUCTION OR OTHER MODIFICATION; (d)
              LOSS OR DAMAGES OF ANY SORT INCURRED AS A RESULT OF DEALINGS WITH
              OR THE PRESENCE OF OFF-WEBSITE LINKS ON THE SITE; (e) VIRUSES,
              SYSTEM FAILURES OR MALFUNCTIONS WHICH MAY OCCUR IN CONNECTION WITH
              YOUR USE OF THE SITE, INCLUDING DURING HYPERLINK TO OR FROM THIRD
              PARTY WEBSITES; (f) ANY INACCURACIES OR OMISSIONS IN CONTENT; OR
              (g) EVENTS BEYOND THE REASONABLE CONTROL OF NYKAA. WE MAKE NO
              REPRESENTATIONS OR WARRANTIES THAT DEFECTS OR ERRORS WILL BE
              CORRECTED
            </p>
            <p className="md:leading-7 pb-5">
              FURTHER, TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER WE NOR
              OUR SUBSIDIARIES, AFFILIATES, PARTNERS, OR LICENSORS WILL BE
              LIABLE FOR ANY INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, OR
              CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING LOST PROFITS) RELATED
              TO THE SITE OR YOUR USE THEREOF REGARDLESS OF THE FORM OF ACTION
              WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE,
              EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES
              AND IN NO EVENT SHALL OUR MAXIMUM AGGREGATE LIABILITY EXCEED.
            </p>
            <p className="md:leading-7 pb-5">
              YOU AGREE THAT NO CLAIMS OR ACTION ARISING OUT OF, OR RELATED TO,
              THE USE OF THE SITE OR THESE TERMS AND CONDITIONS MAY BE BROUGHT
              BY YOU MORE THAN ONE (1) YEAR AFTER THE CAUSE OF ACTION RELATING
              TO SUCH CLAIM OR ACTION AROSE. IF YOU HAVE A DISPUTE WITH US OR
              ARE DISSATISFIED WITH THE SITE, TERMINATION OF YOUR USE OF THE
              SITE IS YOUR SOLE REMEDY. WE HAVE NO OTHER OBLIGATION, LIABILITY,
              OR RESPONSIBILITY TO YOU.
            </p>

            <p className="md:leading-7">
              This disclaimer constitutes an essential part of this Terms of
              Use.
            </p>
          </div>
        </li>

        {/************* 16. Links and Third Party Sites Liability    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">
              16. Links and Third Party Sites Liability
            </h3>
            <p className="md:leading-7">
              References on the Site to any names, marks, products or services
              of third parties or hypertext links to third party sites or
              information are provided solely as a convenience to you. This does
              not in any way constitute or imply NdHealth endorsement,
              sponsorship or recommendation of the third party, information,
              product or service or any association and relationship between
              NdHealth and those third parties.
            </p>
            <p className="md:leading-7 py-5">
              NdHealth is not responsible for the content of any third party
              sites and does not make any representations regarding the content
              or accuracy of material on such sites. If you decide to link to
              any such third party websites, you do so entirely at your own
              risk. NdHealth do not assume any responsibility for examining or
              evaluating the offerings of the off-websites pages or any other
              websites linked from the Site. We shall not be responsible for the
              actions, content, products, or services of such pages and
              websites, including, without limitation, their privacy policies
              and terms and conditions. You should carefully review the terms
              and conditions and privacy policies of all off-website pages and
              other websites that you visit via the Site.
            </p>
          </div>
        </li>

        {/************* 17. Termination    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">17. Termination</h3>
            <p className="md:leading-7">
              These Terms of Use are effective unless and until terminated by
              either you or NdHealth.
            </p>
            <p className="md:leading-7 py-5">
              You may terminate the Terms of Use at any time, provided that you
              discontinue any further use of this Site. NdHealth may terminate
              the Terms of Use at any time and may do so without notice, and
              accordingly deny you access to the Site, such termination will be
              without any liability to the Site.
            </p>

            <p className="md:leading-7">
              Upon any termination of the Terms of Use by either you or
              NdHealth, you must promptly destroy all materials downloaded or
              otherwise obtained from this Site, as well as all copies of such
              materials, whether made under the Terms of Use or otherwise. Any
              such termination of the Terms of Use shall not cancel your
              obligation to pay for the product already ordered from the Site or
              affect any liability that may have arisen under the Terms of Use.
            </p>
          </div>
        </li>

        {/************* 18. Indemnity    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">18. Indemnity</h3>
            <p className="md:leading-7">
              You agree to defend, indemnify and hold harmless NdHealth, its
              employees, directors, officers, agents and their successors and
              assigns, its holding, subsidiaries, affiliates, partners, or
              licensors from and against any and all claims, liabilities,
              damages, losses, costs and expenses, including attorney's fees,
              caused by or arising out of claims based upon your actions or
              inactions, which may result in any loss or liability to NdHealth
              or any third party including but not limited to breach of any
              warranties, representations or undertakings or in relation to the
              non-fulfillment of any of your obligations under the Terms of Use,
              or arising out of the your violation of any applicable laws,
              regulations including but not limited to Intellectual Property
              Rights, payment of statutory dues and taxes, claim of libel,
              defamation, violation of rights of privacy or publicity, loss of
              service by other subscribers and infringement of intellectual
              property or other rights. This clause shall survive the expiry or
              termination of the Terms of Use.
            </p>
          </div>
        </li>

        {/************* 19. Governing Law and Jurisdiction    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">
              19. Governing Law and Jurisdiction
            </h3>
            <p className="md:leading-7">
              The Terms of Use and the Policies shall be construed in accordance
              with the applicable laws of India. For proceedings arising therein
              the Courts at Hyderabad shall have exclusive jurisdiction.
            </p>
            <p className="md:leading-7 py-5">
              Any dispute or difference either in interpretation or otherwise,
              of the Terms of Use and other Policies on the Site, between the
              parties hereto, shall be referred to an independent arbitrator who
              will be appointed mutually and his decision shall be final and
              binding on the parties hereto. The above arbitration shall be in
              accordance with the Arbitration and Conciliation Act, 1996 as
              amended from time to time. The seat and venue of arbitration shall
              be held in Hyderabad.
            </p>
            <p className="md:leading-7 pb-5">
              Without any prejudice to particulars listed in Clause 11 above,
              NdHealth shall have the right to seek and obtain any injunctive,
              provisional or interim relief from any court of competent
              jurisdiction to protect its trade-mark or other intellectual
              property rights or confidential information or to preserve the
              status quo pending arbitration.
            </p>

            <p className="md:leading-7 ">
              For disputes relating to orders outside India, International
              arbitration rules of Indian Arbitration and Conciliation Act 1996
              shall apply. The seat and venue of international arbitration shall
              be Hyderabad.
            </p>
          </div>
        </li>

        {/************* 20. Site Security    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">20. Site Security</h3>
            <p className="md:leading-7">
              You are prohibited from violating or attempting to violate the
              security of the Site, including, without limitation,
            </p>
            <ul className="list-decimal md:pl-3">
              <li className="md:leading-7">
                Accessing data not intended for you or logging onto a server or
                an account which you are not authorized to access;
              </li>
              <li className="md:leading-7">
                Attempting to probe, scan or test the vulnerability of a system
                or network or to breach security or authentication measures
                without proper authorization;
              </li>
              <li className="md:leading-7">
                Attempting to interfere with service to any other user, host or
                network, including, without limitation, via means of submitting
                a virus to the Site, overloading, "flooding," "spamming," "mail
                bombing" or "crashing;"
              </li>
              <li className="md:leading-7">
                Sending unsolicited email, including promotions and/or
                advertising of products or services; or
              </li>
              <li className="md:leading-7">
                Forging any header or any part of the header information in any
                email or newsgroup posting. Violations of system or network
                security may result in civil or criminal liability
              </li>
            </ul>

            <p className="md:leading-7 py-5">
              NdHealth is entitled to investigate occurrences that may involve
              such violations and may involve, and co-operate with, law
              enforcement authorities in prosecuting users who are involved in
              such violations. You agree not to use any device, software or
              routine to interfere or attempt to interfere with the proper
              working of this Site or any activity being conducted on this Site.
              You agree, further, not to use or attempt to use any engine,
              software, tool, agent or other device or mechanism (including
              without limitation browsers, spiders, robots, avatars or
              intelligent agents) to navigate or search this Site other than the
              search engine and search agents available from NdHealth on this
              Site and other than generally available third party web browsers
              (e.g., Netscape Navigator, Microsoft Explorer).
            </p>
          </div>
        </li>

        {/************* 21. Entire Agreement    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">21. Entire Agreement</h3>
            <p className="md:leading-7">
              If any part of these Terms of Use is determined to be invalid or
              unenforceable pursuant to applicable law including, but not
              limited to, the warranty disclaimers and liability limitations set
              forth above, then the invalid or unenforceable provision will be
              deemed to be superseded by a valid, enforceable provision that
              most closely matches the intent of the original provision and the
              remainder of the Terms of Use shall continue to be in effect.
              Unless otherwise specified herein, these Terms of Use constitutes
              the entire agreement between you and NdHealth with respect to the
              Site/services and it supersedes all prior or contemporaneous
              communications and proposals, whether electronic, oral or written.
            </p>

            <p className="md:leading-7 pt-5">
              NdHealth's failure to act with respect to a breach by you or
              others does not waive its right to act with respect to the breach
              or subsequent and similar breaches.
            </p>
          </div>
        </li>

        {/*************  22. General    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">22. General</h3>
            <p className="md:leading-7">
              You acknowledge and hereby agree to these Terms and Conditions and
              that it constitute the complete and exclusive agreement between us
              concerning your use of the Site, and supersede and govern all
              prior proposals, agreements, or other communications.
            </p>

            <p className="md:leading-7 pt-5">
              We reserve the right, in our sole discretion, to change/ alter/
              modify these Terms and Conditions at any time by posting the
              changes on the Site. Any changes are effective immediately upon
              posting to the Site. Your continued use of the Site thereafter
              constitutes your agreement to all such changed Terms and
              Conditions. We may, with or without prior notice, terminate any of
              the rights granted by these Terms and Conditions. You shall comply
              immediately with any termination or other notice, including, as
              applicable, by ceasing all use of the Site.
            </p>

            <p className="md:leading-7 pt-5">
              Nothing contained in these Terms and Conditions shall be construed
              as creating any agency, partnership, affiliation, joint venture or
              other form of joint enterprise between us. Our failure to require
              your performance of any provision hereof shall not affect our full
              right to require such performance at any time thereafter, nor
              shall our waiver of a breach of any provision hereof be taken or
              held to be a waiver of the provision itself. In the event that any
              provision of these Terms and Conditions shall be unenforceable or
              invalid under any applicable law or be so held by any applicable
              arbitral award or court decision, such unenforceability or
              invalidity shall not render these Terms and Conditions
              unenforceable or invalid as a whole but these Terms and Conditions
              shall be modified, to the extent possible, by the adjudicating
              entity to most fully reflect the original intent of the parties as
              reflected in the original provision. The headings in the Terms and
              Conditions are for convenience only and shall not be used in its
              interpretation. If you have any questions regarding these Terms
              and Conditions, please email us at{" "}
              <Link
                className="text-primary hover:underline"
                to={"mailto:support@ndhealth.ai"}
                target="_blank"
              >
                {" "}
                support@ndhealth.ai{" "}
              </Link>
            </p>
          </div>
        </li>

        {/*************  23. CONTACT INFORMATION:    ****************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl pb-5">
              23. CONTACT INFORMATION:
            </h3>
            <h4 className="font-medium">Customer Service Desk</h4>
            <p className="py-1">
              E-mail id:{" "}
              <Link
                className="text-primary hover:underline"
                to={"mailto:support@ndhealth.ai"}
                target="_blank"
              >
                support@ndhealth.ai{" "}
              </Link>
            </p>
            <p className="pb-1">Phone: +91 89776 17888</p>
            <p>Contact Days: Monday to Saturday (8:00 a.m. to 8:00 p.m.)</p>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default ShopTerms;
