import React from "react";

const ProductItem = ({ product, quickView }) => {
  const { image_Url, content, oldPrice, newPrice } = product;

  return (
    <div className="flex flex-col gap-3 p-4 w-56 h-96 group duration-500 hover:shadow-2xl rounded-lg">
      <img
        src={image_Url}
        className="w-48 h-56 object-cover duration-500 mx-auto"
        alt={image_Url}
      />

      <h2 className="font-semibold text-mediumGray line-clamp-2 text-sm">
        {content}
      </h2>

      <h3 className="text-lg font-medium ">
        ₹ {oldPrice ? oldPrice : newPrice}
      </h3>

      {/* Old Price New Price Discount Off Section */}

      {/* <div className="flex items-center gap-1">
    <h3 className="text-lg font-medium ">₹{newPrice}</h3>
    {oldPrice && (
      <div className="flex gap-2">
        <h3 className=" font-medium text-xs line-through">
          (₹{oldPrice})
        </h3>
        <h3 className=" font-semibold text-xs text-green">
          {(((oldPrice - newPrice) / oldPrice) * 100).toFixed()}%
          off
        </h3>
      </div>
    )}
  </div> */}

      <button className=" group-hover:block hidden text-mediumBlue duration-500 font-semibold">
        {quickView}
      </button>
    </div>
  );
};

export default ProductItem;
