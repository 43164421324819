import Logo from "../../../Images/Logos/white Color side-1.png";
import { Link, NavLink } from "react-router-dom";
import LanguageSelector from "../../utils/LanguageSelector/LanguageSelector";
import { useTranslation } from "react-i18next";
import { PiShoppingCartLight } from "react-icons/pi";
import { useCart } from "./CartContext";

const ShopHeader = () => {
  const { t } = useTranslation();

  const { association } = t("headerAndFooter");
  const { cart } = useCart();
  return (
    <header
      className={`flex justify-between items-center bg-mediumBlue py-3 md:px-6 px-3 relative z-10 w-full `}
    >
      <NavLink to="/">
        <img
          src={Logo}
          alt="logo"
          className="hover:scale-105 ease-out duration-500 lg:w-44 md:w-40 w-36"
        />
      </NavLink>
      <div className="flex gap-5 items-center">
        <LanguageSelector />

        {/*********   Shopping Cart Icon ***************/}

        <Link to="/cart">
          <div className="relative flex items-center justify-center cursor-pointer">
            <PiShoppingCartLight
              size={30}
              className="text-white hover:text-Orange duration-500"
            />
            <div className="bg-Orange absolute text-white text-[0.65rem] -right-2 bottom-4 rounded-full px-2 py-[0.15rem] font-semibold ">
              {cart?.length}
            </div>
          </div>{" "}
        </Link>
      </div>
    </header>
  );
};

export default ShopHeader;
