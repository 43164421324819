import { Link } from "react-router-dom";
import ErrorImg from "../../Images/Error Image.png";
const Error = () => {
  return (
    <div className="flex md:flex-row flex-col items-center justify-center md:gap-10 gap-3">
      <img
        className="md:w-96 w-60 md:mt-[5%]"
        src={ErrorImg}
        alt="Medicine Error Image"
      />
      <div className="text-center md:p-5 md:w-96">
        <h1 className="md:text-5xl text-3xl font-semibold">404 Not Found</h1>
        <p className=" md:py-5 py-3">It looks like you are lost</p>
        <p>
          The page you are looking for no longer exist. Don’t stress, we have a
          way out for you
        </p>
        <Link to={"/"}>
          <button className="styleButton md:mt-10 mt-5 px-6 py-2">
            Go to Home
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Error;
