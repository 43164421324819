import React from "react";
import { Helmet } from "react-helmet";

const MetaInfo = ({
  pageTitle,
  pageDescription,
  pageKeywords,
  ogTitle,
  ogUrl,
  ogDescription,
  ogType,
  ogImage,
  canonicalUrl,
}) => {
  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:site_name" content="ND Health" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={ogImage} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    </div>
  );
};

export default MetaInfo;
