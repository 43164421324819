import React from "react";

const ProductDesc = ({ productDescription, productDesc }) => {
  return (
    <div className="py-5 sm:w-1/2 mx-auto md:mx-20 md:text-base text-sm">
      <h2 className="font-semibold">{productDesc}</h2>
      <div className="text-mediumGray">
        {productDescription &&
          productDescription.map((desc) => {
            return (
              <p key={desc?.proDescId} className="py-2">
                {desc?.proDesc}
              </p>
            );
          })}
      </div>
    </div>
  );
};

export default ProductDesc;
