import { Link } from "react-router-dom";
import MetaInfo from "../../../Meta/Meta-Info";

const ShopCancel = () => {
  return (
    <section className="border-[1rem] sm:border-[3rem] border-lightBlue p-4 sm:p-8 flex flex-col gap-10  md:text-base text-sm">
      {/* Meta PageTitle and PageDescription */}

      <MetaInfo
        pageTitle={" Cancel Your NDHealth Subscription - Easy and Secure"}
        pageDescription={
          "Need to cancel your NDHealth subscription? We make it simple. Learn how to cancel your account quickly and securely. Start the process now."
        }
        pageKeywords={
          ("Cancel NDHealth Subscription",
          "Unsubscribe NDHealth",
          "How to Cancel NDHealth Subscription",
          "NDHealth Account Cancellation",
          "How to Cancel NDHealth Subscription")
        }
      />

      <h1 className="lg:text-5xl md:text-2xl text-xl font-medium text-center sm:py-5 font-poppins">
        CANCELLATION AND RETURN POLICY
      </h1>
      <ul className="flex flex-col gap-7">
        {/************* Cancellation    *********************/}
        <li>
          <div className="md:text-base text-sm">
            <h3 className="md:text-2xl text-lg ">
              Cancellation before shipment (Only valid on orders placed &
              delivery in India):
            </h3>

            <p className="md:leading-7 pb-3">
              If the order of the item(s) that you want to cancel has not been
              shipped yet, you can raise your cancellation request via the
              following routes :
            </p>
            <ul className="md:md:pl-10 md:text-base text-sm">
              <li>
                A. Via 'My Orders' section Go to your account → Click on
                'Orders' → Go to the order you want to cancel and select 'Order
                Details' → Click on 'Cancel Item' → Select the item(s) in your
                order that you would like to cancel and provide the reason for
                cancellation and 'Confirm' → Once submitted, your item(s) will
                be cancelled and your refund will be processed.
              </li>
              <li className="py-3">
                B. Via the Help Center Click on 'Have a Concern' → 'Write To Us'
                → 'Submit a Request' → Provide us with your order ID details and
                your request to cancel your order. → Our service representative
                will review your query and revert in 24 hours.
              </li>
              <li>
                C. Via Email or Call Send an email to our customer support team
                at
                <Link
                  className="text-primary hover:underline"
                  to={"mailto:support@ndhealth.ai"}
                >
                  {" "}
                  support@ndhealth.ai{" "}
                </Link>
                to cancel your order along with your order ID details. Or call
                us on +91 89776 17888 (Monday To Saturday, 8AM to 8PM).
              </li>
            </ul>
          </div>
        </li>

        {/************* How will I get refunded for the cancelled orders and how long will this process take?    *********************/}
        <li className=" md:text-base text-sm">
          <div>
            <h3 className="md:text-2xl text-xl ">
              How will I get refunded for the cancelled orders and how long will
              this process take?
            </h3>

            <p className="md:leading-7 py-3">
              In case of cancellation before shipment, we initiate the refund
              within 24-48 business hours after receiving the cancellation
              request.
            </p>
            <p className="md:leading-7 pb-3">
              In case of cancellation once the shipment has already been
              dispatched or if it is being returned, we process the refund once
              the products have been received and verified at our warehouse.
            </p>
            <ul className="md:md:pl-10">
              <li>
                • For payments done through{" "}
                <b>credit/debit cards or net banking</b>, the refund will be
                initiated to the same account from which the payment was made
                within 24-48 business hours of us receiving the products back.
                It may take 5 business days for the amount to reflect in your
                account.
              </li>
              <li className="py-3">
                • COD refund will not be applicable in Cancellation scenario as
                Pre dispatch will not involve cash transaction & post dispatch
                leads to refund in source account.It will take an additional 5
                business days for the amount to reflect in your account.
              </li>
              <li>• We do not offer wallet refunds for cancellations</li>
            </ul>
          </div>
        </li>

        {/************* What if I used discount vouchers or loyalty points during time of payment and I have to cancel my order?    *********************/}
        <li className=" md:text-base text-sm">
          <div>
            <h3 className="md:text-2xl text-xl ">
              What if I used discount vouchers or loyalty points during time of
              payment and I have to cancel my order?
            </h3>

            <p className="md:leading-7 py-3">
              Discount vouchers are intended for one-time use only and shall be
              treated as used even if you cancel the order.
            </p>
            <p className="md:leading-7 pb-3">
              If you had redeemed loyalty points for an order, the same will be
              credited back to your account in the case of a cancellation.
            </p>
          </div>
        </li>

        {/************* Returns, Replacements and Refunds    *********************/}
        <li>
          <div>
            <h2 className="md:text-2xl text-xl font-medium text-center pb-5">
              Returns, Replacements and Refunds
            </h2>

            <h3 className="md:text-2xl text-xl">
              How do I return an item purchased on Ndhealth.shop?
            </h3>

            <h4 className="py-3">Indian Orders:</h4>

            <p className="md:leading-7 py-3">
              Ndhealth offers its customers an ’Easy return policy’, wherein you
              can raise a return/exchange request of a product within 15 days of
              its delivery. We also accept partial returns wherein you can raise
              a return request for one or all products in your order.
            </p>

            <ul className="md:ml-10">
              <li>
                <p>• Step 1: Follow one of the following steps:</p>
                <ul className="md:ml-10">
                  <li className="md:leading-7">
                    a. Go to ‘Account’ section Click on 'Orders'. Go to the
                    order and select 'Have a Concern'. Proceed with reason for
                    return.
                  </li>
                  <li className="md:leading-7">
                    b. Via Help Center Click on 'Write to us' → Click on 'Submit
                    a Request' → Provide us with your order details along with
                    your relevant request. Kindly share an image of the product
                    and the invoice for our reference
                  </li>
                  <li className="md:leading-7">
                    c. Via Email Share an email to our Customer support team at
                    <Link
                      className="text-primary hover:underline"
                      target="_blank"
                      to={"mailto:support@ndhealth.ai"}
                    >
                      {" "}
                      support@ndhealth.ai{" "}
                    </Link>{" "}
                    within 15 business days of receiving the order.
                  </li>
                </ul>
              </li>
              <li>
                • Step 2: Once your request is received, based on the outcome of
                the return raised, we will pick the item from you. We will
                initiate the refund or replacement process only if the products
                are received by us in their original packaging with their seals,
                labels and bar-codes intact.
              </li>
            </ul>
            <p className="md:leading-7 py-3">
              <b> Note : </b>If it is a case of replacement, it is subject to
              the availability of stock. In cases when a replacement may not be
              available, we will refund you the full amount.
            </p>
          </div>
        </li>

        {/***   Which are the items that cannot be returned/exchanged?   ***/}
        <li>
          <div>
            <h2 className="md:text-2xl text-xl font-medium ">
              Which are the items that cannot be returned/exchanged?
            </h2>

            <h3 className="md:text-2xl text-xl py-3">
              Returns will not be accepted under the following conditions:{" "}
            </h3>

            <ul className="md:pl-10">
              <li>• Product is damaged due to misuse/overuse</li>
              <li className="py-3">
                • Returned without original packaging including, price tags,
                labels, original packing, freebies and other accessories or if
                original packaging is damaged
              </li>
              <li>• Serial Number is tampered with.</li>
              <li className="py-3">
                • Defective products that are not covered under
                Seller/Manufacturer’s warranty
              </li>
              <li>• Product is used or altered</li>
              <li className="py-3">
                • If request is initiated after 15 business days of order
                delivery
              </li>
              <li>• Free product provided by brand</li>
            </ul>
            <h3 className="md:text-2xl text-xl py-3">
              Categories not eligible for Return:
            </h3>
            <ul className="md:pl-10">
              <li>
                • Customized Products (e.g.: idly / dosa batter mix, cookies,
                millets, Taila etc) cannot be returned since they are available
                during select promotions and ordered on demand
              </li>
              <li className="py-3">
                • Personal care appliances cannot be returned due to hygiene
                issues
              </li>
            </ul>

            <p className="md:leading-7 py-3">
              <b> Note : </b>For certain marketing campaigns or mega sale
              periods, special return/exchange/refund rules may apply.
              Information regarding this is visible on the promotion banner. For
              any clarification, please feel free to contact our customer care.
            </p>
          </div>
        </li>

        {/*************I have received a damaged or defective item/wrong product in my order, how should I proceed?    *********************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl ">
              I have received a damaged or defective item/wrong product in my
              order, how should I proceed?
            </h3>

            <p className="md:leading-7 py-3">
              Our shipments go through rigorous quality check processes before
              they leave our warehouse. However in the rare case that your
              product is damaged during shipment or transit, you can request for
              a replacement or cancellation and refund.
            </p>
            <p className="md:leading-7 pb-3">
              If you have received an item in a damaged/defective condition or
              have been sent a wrong product, you can follow a few simple steps
              to initiate your return/refund within 15 days of receiving the
              order:
            </p>
            <ul className="md:ml-10">
              <li>
                <p>• Step 1: Follow one of the following steps:</p>
                <ul className="md:ml-10">
                  <li className="md:leading-7">
                    a. Go to ‘Account’ section Click on 'Orders'. Go to the
                    order and select 'Have a Concern'. Proceed with reason for
                    return.
                  </li>
                  <li className="md:leading-7">
                    b. Via Help Center Click on 'Write to us' → Click on 'Submit
                    a Request' → Provide us with your order details along with
                    your relevant request. Kindly share an image of the product
                    and the invoice for our reference
                  </li>
                  <li className="md:leading-7">
                    c. Via Email Share an email to our Customer support team at
                    <Link
                      className="text-primary hover:underline"
                      target="_blank"
                      to={"mailto:support@ndhealth.ai"}
                    >
                      {" "}
                      support@ndhealth.ai{" "}
                    </Link>{" "}
                    within 15 business days of receiving the order.
                  </li>
                </ul>
              </li>
              <li>
                • Step 2: Once your request is received, based on the outcome of
                the return raised, we will pick the item from you. We will
                initiate the refund or replacement process only if the products
                are received by us in their original packaging with their seals,
                labels and bar-codes intact.
              </li>
            </ul>

            <p className="md:leading-7 py-3">
              <b> Note : </b>If it is a case of replacement, it is subject to
              the availability of stock. In cases when a replacement may not be
              available, we will refund you the full amount.
            </p>
          </div>
        </li>

        {/************* Do I have to return the free gift when I return a product?    *********************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl ">
              Do I have to return the free gift when I return a product?
            </h3>

            <p className="md:leading-7 py-3">
              Yes. The free gift is included as a part of the item order and
              needs to be returned along with the originally delivered product.
            </p>
          </div>
        </li>

        {/************* Can I return part of my order?    *********************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl ">
              Can I return part of my order?
            </h3>

            <p className="md:leading-7 py-3">
              Yes. A return can be created at item level and if you have ordered
              multiple items, you can initiate a return/replacement/refund for
              any individual item. However, any product being returned needs to
              be returned in full including all components as well as any
              complimentary gifts or products which came along with it
            </p>
          </div>
        </li>

        {/*************How will I get refunded for the returned orders and how long will this process take?    *********************/}
        <li>
          <div>
            <h3 className="md:text-2xl text-xl ">
              How will I get refunded for the returned orders and how long will
              this process take?
            </h3>

            <p className="md:leading-7 pb-3">
              In case of a return/replacement/refund, we process the refund once
              the products have been received and verified at our warehouse.
            </p>
            <ul className="md:pl-10">
              <li>
                • For payments done through{" "}
                <b>credit/debit cards or net banking</b>
                ,the refund will be initiated to the same account from which the
                payment was made within 24-48 business hours of us receiving the
                products back. It may take 5 business days for the amount to
                reflect in your account.
              </li>
              <li className="py-3">
                • For <b> cash on delivery transactions </b>, we will initiate a
                bank transfer against the refund amount against the billing
                details shared by you. This process will be completed within
                24-48 business hours of us receiving the products back. It will
                take 5 business days for the amount to reflect in your account.
              </li>
              <li>
                • In addition, we also provide the hassle-free option of refund
                through ndhealth coupons, which can be used during future
                purchase.
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default ShopCancel;
