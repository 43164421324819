export const languageOptions = [
  {
    value: "select",
    label: "Select",
  },
  {
    value: "telugu",
    label: "Telugu",
  },
  {
    value: "hindi",
    label: "Hindi",
  },
  {
    value: "english",
    label: "English",
  },
];

export const timeSlotOption = [
  {
    value: "select",
    label: "Select",
  },
  {
    value: "10am",
    label: "10 AM - 1 PM",
  },
  {
    value: "1pm",
    label: "1 PM - 5 PM",
  },
];

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
