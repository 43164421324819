import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import FoxtailMillet1 from "../Images/Shop/VasudhaFoods/Foxtail millets cookies/Foxtail millets cookies-1.webp";
import FoxtailMillet2 from "../Images/Shop/VasudhaFoods/Foxtail millets cookies/Foxtail millets cookies-2.webp";
import FoxtailMillet3 from "../Images/Shop/VasudhaFoods/Foxtail millets cookies/Foxtail millets cookies-3.webp";

import NavaGrain1 from "../Images/Shop/VasudhaFoods/Nava Grain/NavaGrain1.webp";
import NavaGrain2 from "../Images/Shop/VasudhaFoods/Nava Grain/NavaGrain2.webp";
import NavaGrain3 from "../Images/Shop/VasudhaFoods/Nava Grain/NavaGrain3.webp";

import BakedCrispsIN1 from "../Images/Shop/VasudhaFoods/BakedCrisps/BakedCrisps-1.webp";
import BakedCrispsIN2 from "../Images/Shop/VasudhaFoods/BakedCrisps/BakedCrisps-2.webp";
import BakedCrispsIN3 from "../Images/Shop/VasudhaFoods/BakedCrisps/BakedCrisps-3.webp";

import BakedCrispsH1 from "../Images/Shop/VasudhaFoods/BakedCrispsH/BakedCrispsH-1.webp";
import BakedCrispsH2 from "../Images/Shop/VasudhaFoods/BakedCrispsH/BakedCrispsH-2.webp";
import BakedCrispsH3 from "../Images/Shop/VasudhaFoods/BakedCrispsH/BakedCrispsH-3.webp";

import LittleMilletCookie1 from "../Images/Shop/VasudhaFoods/LittleMilletCookie/LittleMilletCookie-1.webp";
import LittleMilletCookie2 from "../Images/Shop/VasudhaFoods/LittleMilletCookie/LittleMilletCookie-2.webp";
import LittleMilletCookie3 from "../Images/Shop/VasudhaFoods/LittleMilletCookie/LittleMilletCookie-3.webp";

import PowerBarMillet1 from "../Images/Shop/VasudhaFoods/PowerBarMilletChikki/PowerBarMilletChikki-1.webp";
import PowerBarMillet2 from "../Images/Shop/VasudhaFoods/PowerBarMilletChikki/PowerBarMilletChikki-2.webp";
import PowerBarMillet3 from "../Images/Shop/VasudhaFoods/PowerBarMilletChikki/PowerBarMilletChikki-3.webp";

// import common_en from "../locale/common/en.json";
// import common_hi from "../locale/common/hi.json";
// import common_tel from "../locale/common/tel.json";

// const resources = {
//   common: {
//     en: common_en,
//     hi: common_hi,
//     tel: common_tel,
//   },
// };

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    returnObjects: true,

    resources: {
      en: {
        translation: {
          common: {
            enquiryBtn: "Enquire Now",
            associates: "In Association With",
            contactBtn: "Contact Us",
            submitBtn: "Submit",
            knownBtn: "Known More",
            viewBtn: "View More",
            homeBtn: " Go to Homepage ",
            thankYou: "Thank You!",
            getBack: "for contacting us,We will get back to you soon...",
          },
          headerAndFooter: {
            home: "Home",
            programs: "Programs",
            diabetes: "Diabetes",
            dyslipedimia: "Dyslipedimia",
            pcod: "PCOD",
            obesity: "Obesity",
            psoriasis: "Psoriasis",
            antiaging: "Anti-Aging",
            nafld: "NAFLD",
            shop: "Shop",
            whatwedo: "What we do",
            blog: "Blog",
            cart: "Cart",
            contactus: "Contact Us",
            ndhealthDesc:
              "Explore ND Health.AI’s programs for Diabetes, Dyslipidemia, & PCOD. Personalized care for your health journey. Start transforming today",
            quickLinks: "Quick Links",
            ourPrograms: "Our Programs",
            otherLinks: "Other Links",
            privacy: "Privacy",
            terms: "Terms",
            shipping: "Shipping",
            cancellation: "Cancellation",
            association: "In Association With",
            followUs: "Follow Us",
          },

          shop: {
            coming: "We're Coming Soon",
            tuned: "Stay tuned ",
            homeBtn: " Go to Homepage ",
            formPlaceholder: "Search for Medicines and Health Products",
            categoryProduct: "Hot Seller",
            quickView: " Quick View",
            productDesc: "Product Description",
            selectQty: "Select Quantity",
            qtyEmpty: "Quantity cannot be empty",
            item: "Item",
            items: "Items",
            addToCartBtn: "Add to cart",
            viewCart: "View Cart",
            forOrder: {
              orderText: "For Order Please Contact this Number : ",
              orderNumber: "+91 89776 17888",
            },

            //  "For Order Please Contact this Number : + 91 89776 17888"

            shopData: [
              {
                id: 1,
                image_Url: BakedCrispsIN1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: BakedCrispsIN1 },
                  { smallImageId: 2, smallImageUrl: BakedCrispsIN2 },
                  { smallImageId: 3, smallImageUrl: BakedCrispsIN3 },
                ],
                content: "Baked Crisps | Indian Tomato ",
                newPrice: " 20.00",
                productDetails: {
                  manufactureTitle: "Manufacturer/Marketer",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "Return Policy",
                  returnPolicy: "Not Returnable",

                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },

                productDescription: [
                  {
                    proDescId: 1,
                    proDesc:
                      "Delight your taste buds with the crunchy goodness of multi-grain millets, offering a harmonious blend of flavors and textures in every bite. The spices sourced from traditional Indian tomatoes add a burst of authentic taste, creating a snacking experience that's both delicious and culturally rich.        ",
                  },
                  {
                    proDescId: 2,
                    proDesc:
                      "As you savor each crisp, rest assured that you are enjoying a snack free from artificial enhancements. Our Baked Crisps are a guilt-free indulgence, providing a satisfying and flavorful treat without compromising on your commitment to natural goodness. Elevate your snacking experience with our wholesome and spice-infused Baked Crisps – a fusion of tradition and taste!.          ",
                  },
                ],
              },

              {
                id: 2,
                image_Url: BakedCrispsH1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: BakedCrispsH1 },
                  { smallImageId: 2, smallImageUrl: BakedCrispsH2 },
                  { smallImageId: 3, smallImageUrl: BakedCrispsH3 },
                ],
                content: "Baked Crisps | Home Made Masala ",
                newPrice: " 20.00",
                productDetails: {
                  manufactureTitle: "Manufacturer/Marketer",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "Return Policy",
                  returnPolicy: "Not Returnable",

                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
              },
              {
                id: 3,
                image_Url: FoxtailMillet1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: FoxtailMillet1 },
                  { smallImageId: 2, smallImageUrl: FoxtailMillet2 },
                  { smallImageId: 3, smallImageUrl: FoxtailMillet3 },
                ],
                content: "Foxtail Millet Cookies ",
                oldPrice: "70.00",
                newPrice: " 50.00",
                productDetails: {
                  manufactureTitle: "Manufacturer/Marketer",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "Return Policy",
                  returnPolicy: "Not Returnable",
                  specialityTitle: "Speciality",
                  specialityDesc1: "Zero Maida | Zero Sugar",
                  specialityDesc2:
                    "0% Transfat | 0% Additives | 0% Preservatives ",
                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
                productDescription: [
                  {
                    proDescId: 1,
                    proDesc:
                      "Savor the wholesome charm of our Little Millet Cookies – a delightful creation crafted with care. Immerse yourself in the goodness of little millet, as these cookies are made exclusively with this nutritious grain, known for its exceptional health benefits.",
                  },
                  {
                    proDescId: 2,
                    proDesc:
                      "Our Little Millet Cookies offer a unique blend of taste and nutrition, providing a crunchy texture that will leave you craving for more. Rich in essential nutrients like iron, fiber, and B-vitamins, these cookies are a guilt-free indulgence that contributes to your overall well-being.",
                  },
                  {
                    proDescId: 3,
                    proDesc:
                      "Elevate your snack time with the pure goodness of little millet, celebrated in every delightful bite. These cookies are a delicious way to embrace the nutritional richness of this wholesome grain. Experience the joy of guilt-free indulgence with our Little Millet Cookies – the perfect balance of flavor and health in every mouthwatering treat!",
                  },
                ],
              },

              {
                id: 4,
                image_Url: NavaGrain1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: NavaGrain1 },
                  { smallImageId: 2, smallImageUrl: NavaGrain2 },
                  { smallImageId: 3, smallImageUrl: NavaGrain3 },
                ],
                content: "Nava Grains Cookies ",
                oldPrice: "70.00",
                newPrice: " 50.00",
                productDetails: {
                  manufactureTitle: "Manufacturer/Marketer",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "Return Policy",
                  returnPolicy: "Not Returnable",
                  specialityTitle: "Speciality",
                  specialityDesc1: "Zero Maida | Zero Sugar",
                  specialityDesc2:
                    "0% Transfat | 0% Additives | 0% Preservatives ",
                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
                productDescription: [
                  {
                    proDescId: 1,
                    proDesc:
                      "Indulge in the wholesome goodness of Nava Grain Cookies – a delightful treat crafted from a unique blend of nine wholesome grains. Made with finger millet, kodo millet, pearl millet, barnyard millet, little millet, foxtail millet, proso millet, sorghum, and whole wheat flour, these cookies offer a symphony of flavors and nutritional benefits.        ",
                  },
                  {
                    proDescId: 2,
                    proDesc:
                      "Each crunchy bite is a celebration of the rich diversity of grains, providing a harmonious fusion of taste and health. These cookies are a source of essential nutrients like fiber, vitamins, and minerals, contributing to a well-balanced and nourishing snack.          ",
                  },
                  {
                    proDescId: 3,
                    proDesc:
                      "Nava Grain Cookies are not just a treat for your taste buds; they are a wholesome choice for those seeking a delicious and nutrient-packed snack. Elevate your snacking experience with the goodness of nine grains – savor the flavor, embrace the nutrition!",
                  },
                ],
              },

              {
                id: 5,
                image_Url: LittleMilletCookie1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: LittleMilletCookie1 },
                  { smallImageId: 2, smallImageUrl: LittleMilletCookie2 },
                  { smallImageId: 3, smallImageUrl: LittleMilletCookie3 },
                ],
                content: "Little Millet Cookies    ",
                oldPrice: "70.00",
                newPrice: " 50.00",
                productDetails: {
                  manufactureTitle: "Manufacturer/Marketer",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "Return Policy",
                  returnPolicy: "Not Returnable",
                  specialityTitle: "Speciality",
                  specialityDesc1: "Zero Maida | Zero Sugar",
                  specialityDesc2:
                    "0% Transfat | 0% Additives | 0% Preservatives ",

                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
                productDescription: [
                  {
                    proDescId: 1,
                    proDesc:
                      "Savor the wholesome charm of our Little Millet Cookies – a delightful creation crafted with care. Immerse yourself in the goodness of little millet, as these cookies are made exclusively with this nutritious grain, known for its exceptional health benefits. ",
                  },
                  {
                    proDescId: 2,
                    proDesc:
                      "Our Little Millet Cookies offer a unique blend of taste and nutrition, providing a crunchy texture that will leave you craving for more. Rich in essential nutrients like iron, fiber, and B-vitamins, these cookies are a guilt-free indulgence that contributes to your overall well-being.          ",
                  },
                  {
                    proDescId: 3,
                    proDesc:
                      "Elevate your snack time with the pure goodness of little millet, celebrated in every delightful bite. These cookies are a delicious way to embrace the nutritional richness of this wholesome grain. Experience the joy of guilt-free indulgence with our Little Millet Cookies – the perfect balance of flavor and health in every mouthwatering treat! ",
                  },
                ],
              },

              {
                id: 6,
                image_Url: PowerBarMillet1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: PowerBarMillet1 },
                  { smallImageId: 2, smallImageUrl: PowerBarMillet2 },
                  { smallImageId: 3, smallImageUrl: PowerBarMillet3 },
                ],
                content: " Power Bar | Millet Chikki  ",
                newPrice: " 12.00",
                productDetails: {
                  manufactureTitle: "Manufacturer/Marketer",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "Return Policy",
                  returnPolicy: "Not Returnable",
                  specialityTitle: "Speciality",
                  specialityDesc1: "Zero Maida | Zero Sugar",
                  specialityDesc2:
                    "0% Transfat | 0% Additives | 0% Preservatives ",

                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
              },
            ],
          },

          cart: {
            emptyText: "YOUR CART IS EMPTY ",
            shopMoreText:
              "We have all the medicines and healthcare products that you need.",
            goToShopPageBtn: "Go To Shop Page",
          },
        },
      },

      hi: {
        translation: {
          common: {
            enquiryBtn: "अभी पूछताछ करें",
            associates: "के सहयोग से",
            contactBtn: "संपर्क करें",
            submitBtn: "जमा करना",
            knownBtn: "अधिक ज्ञात",
            viewBtn: "और देखें",
            homeBtn: "मुखपृष्ठ प्र जाएं",
            thankYou: "धन्यवाद!",
            getBack:
              "हमसे संपर्क करने के लिए धन्यवाद, हम जल्द ही आपसे संपर्क करेंगे...",
          },
          headerAndFooter: {
            home: "मुखपृष्ठ",
            programs: "कार्यक्रमों",
            diabetes: "मधुमेह",
            dyslipedimia: "डिस्लिपिडेमिया",
            pcod: "PCOD",
            obesity: "मोटापा",
            psoriasis: "सोरायसिस",
            antiaging: "बुढ़ापा विरोधी",
            nafld: "NAFLD",
            shop: "दुकान",
            whatwedo: "हम क्या करते हैं",
            blog: "ब्लॉग",
            cart: "कार्ट",
            contactus: "संपर्क करें",
            ndhealthDesc:
              "मधुमेह, डिस्लिपिडेमिया और पीसीओएस के लिए AND हेल्थ.एआई के कार्यक्रमों का अन्वेषण करें। आपकी स्वास्थ्य यात्रा के लिए वैयक्तिकृत देखभाल। आज ही बदलना शुरू करें",
            quickLinks: "त्वरित सम्पक",
            ourPrograms: "हमारे कार्यक्रम",
            otherLinks: "अन्य लिंक",
            privacy: "गोपनीयता",
            terms: "निबंधन",
            shipping: "शिपिंग",
            cancellation: "रद्द करना",
            association: "के सहयोग से",
            followUs: "हमारे पर का पालन करें",
          },

          shop: {
            coming: "हम जल्द आ रहे हैं ",
            tuned: "बने रहें ",
            homeBtn: "मुखपृष्ठ प्र जाएं",
            formPlaceholder: "दवाइयों और स्वास्थ्य उत्पादों की खोज करें",
            categoryProduct: "हॉट सेलर",
            quickView: "त्वरित देखें",
            productDesc: "उत्पाद वर्णन",
            selectQty: "मात्रा चुनें",
            qtyEmpty: "मात्रा रिक्त नहीं हो सकती",
            item: "वस्तु",
            items: "सामान",
            addToCartBtn: "कार्ट में जोड़ें",
            viewCart: "टोकरी देखें",

            forOrder: {
              orderText: "ऑर्डर के लिए कृपया इस नंबर पर संपर्क करें : ",
              orderNumber: "+91 89776 17888",
            },

            shopData: [
              {
                id: 1,
                image_Url: BakedCrispsIN1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: BakedCrispsIN1 },
                  { smallImageId: 2, smallImageUrl: BakedCrispsIN2 },
                  { smallImageId: 3, smallImageUrl: BakedCrispsIN3 },
                ],
                content: "बेक्ड क्रिस्प्स | भारतीय टमाटर",
                newPrice: " 20.00",
                productDetails: {
                  manufactureTitle: "निर्माता/विपणक",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "वापसी नीति",
                  returnPolicy: "वापसी योग्य नहीं",

                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },

                productDescription: [
                  {
                    proDescId: 1,
                    proDesc:
                      "मल्टी-ग्रेन बाजरे की कुरकुरी अच्छाई से अपने स्वाद को खुश करें, हर निवाले में स्वाद और बनावट का सामंजस्यपूर्ण मिश्रण प्रदान करें। पारंपरिक भारतीय टमाटरों से प्राप्त मसाले प्रामाणिक स्वाद का एक विस्फोट जोड़ते हैं, जो एक ऐसा स्नैकिंग अनुभव बनाता है जो स्वादिष्ट और सांस्कृतिक रूप से समृद्ध दोनों है। ",
                  },
                  {
                    proDescId: 2,
                    proDesc:
                      "जैसे-जैसे आप प्रत्येक क्रिस्प का स्वाद लेते हैं, निश्चिंत रहें कि आप कृत्रिम संवर्द्धन से मुक्त स्नैक का आनंद ले रहे हैं। हमारे बेक्ड क्रिस्प्स एक अपराध-मुक्त भोग हैं, जो प्राकृतिक अच्छाई के प्रति आपकी प्रतिबद्धता से समझौता किए बिना एक संतोषजनक और स्वादिष्ट उपचार प्रदान करते हैं। हमारे पौष्टिक और मसालेदार बेक्ड क्रिस्प्स के साथ अपने स्नैकिंग अनुभव को बढ़ाएँ - परंपरा और स्वाद का एक मिश्रण!        ",
                  },
                ],
              },

              {
                id: 2,
                image_Url: BakedCrispsH1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: BakedCrispsH1 },
                  { smallImageId: 2, smallImageUrl: BakedCrispsH2 },
                  { smallImageId: 3, smallImageUrl: BakedCrispsH3 },
                ],
                content: "बेक्ड क्रिस्प्स | होम मेड मसाला ",
                newPrice: " 20.00",
                productDetails: {
                  manufactureTitle: "निर्माता/विपणक",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "वापसी नीति",
                  returnPolicy: "वापसी योग्य नहीं",

                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
              },
              {
                id: 3,
                image_Url: FoxtailMillet1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: FoxtailMillet1 },
                  { smallImageId: 2, smallImageUrl: FoxtailMillet2 },
                  { smallImageId: 3, smallImageUrl: FoxtailMillet3 },
                ],
                content: "फॉक्सटेल बाजरा कुकीज़",
                oldPrice: "70.00",
                newPrice: " 50.00",
                productDetails: {
                  manufactureTitle: "निर्माता/विपणक",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "वापसी नीति",
                  returnPolicy: "वापसी योग्य नहीं",
                  specialityTitle: "स्पेशलिटी",
                  specialityDesc1: "जीरो मैदा | जीरो शुगर",
                  specialityDesc2: "0% ट्रांसफैट | 0% योजक | 0% परिरक्षक ",
                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
                productDescription: [
                  {
                    proDescId: 1,
                    proDesc:
                      "हमारे लिटिल मिलेट कुकीज़ के स्वास्थ्यवर्धक आकर्षण का आनंद लें - एक शानदार रचना जिसे सावधानी से बनाया गया है। लिटिल मिलेट की अच्छाई में खुद को डुबोएँ, क्योंकि ये कुकीज़ विशेष रूप से इस पौष्टिक अनाज से बनाई जाती हैं, जो अपने असाधारण स्वास्थ्य लाभों के लिए जानी जाती है।",
                  },
                  {
                    proDescId: 2,
                    proDesc:
                      "हमारी लिटिल मिलेट कुकीज़ स्वाद और पोषण का एक अनूठा मिश्रण प्रदान करती हैं, जो एक कुरकुरा बनावट प्रदान करती हैं जो आपको और अधिक खाने के लिए तरस जाएगी। आयरन, फाइबर और बी-विटामिन जैसे आवश्यक पोषक तत्वों से भरपूर, ये कुकीज़ एक अपराध-मुक्त भोग हैं जो आपके समग्र कल्याण में योगदान देता है।",
                  },
                  {
                    proDescId: 3,
                    proDesc:
                      "अपने नाश्ते के समय को छोटे बाजरे की शुद्ध अच्छाई के साथ बढ़ाएँ, जो हर स्वादिष्ट निवाले में समाहित है। ये कुकीज़ इस पौष्टिक अनाज की पौष्टिक समृद्धि को अपनाने का एक स्वादिष्ट तरीका है। हमारे छोटे बाजरे की कुकीज़ के साथ अपराध-मुक्त भोग का आनंद लें - हर मुंह में पानी लाने वाले व्यंजन में स्वाद और स्वास्थ्य का सही संतुलन!",
                  },
                ],
              },

              {
                id: 4,
                image_Url: NavaGrain1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: NavaGrain1 },
                  { smallImageId: 2, smallImageUrl: NavaGrain2 },
                  { smallImageId: 3, smallImageUrl: NavaGrain3 },
                ],
                content: "नवा ग्रेन्स कुकीज़ ",
                oldPrice: "70.00",
                newPrice: " 50.00",
                productDetails: {
                  manufactureTitle: "निर्माता/विपणक",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "वापसी नीति",
                  returnPolicy: "वापसी योग्य नहीं",
                  specialityTitle: "स्पेशलिटी",
                  specialityDesc1: "जीरो मैदा | जीरो शुगर",
                  specialityDesc2: "0% ट्रांसफैट | 0% योजक | 0% परिरक्षक ",
                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
                productDescription: [
                  {
                    proDescId: 1,
                    proDesc:
                      "नव ग्रेन कुकीज़ की पौष्टिक अच्छाई का आनंद लें - नौ पौष्टिक अनाजों के एक अनूठे मिश्रण से तैयार एक स्वादिष्ट व्यंजन। फिंगर बाजरा, कोदो बाजरा, मोती बाजरा, बार्नयार्ड बाजरा, छोटा बाजरा, फॉक्सटेल बाजरा, प्रोसो बाजरा, सोरघम और पूरे गेहूं के आटे से बने, ये कुकीज़ स्वाद और पोषण संबंधी लाभों की एक सिम्फनी प्रदान करते हैं।",
                  },
                  {
                    proDescId: 2,
                    proDesc:
                      "प्रत्येक कुरकुरी बाइट अनाज की समृद्ध विविधता का उत्सव है, जो स्वाद और स्वास्थ्य का सामंजस्यपूर्ण मिश्रण प्रदान करता है। ये कुकीज़ फाइबर, विटामिन और खनिजों जैसे आवश्यक पोषक तत्वों का स्रोत हैं, जो एक संतुलित और पौष्टिक नाश्ता बनाने में योगदान करते हैं।        ",
                  },
                  {
                    proDescId: 3,
                    proDesc:
                      "नवा ग्रेन कुकीज़ सिर्फ़ आपके स्वाद के लिए ही नहीं हैं; वे स्वादिष्ट और पोषक तत्वों से भरपूर स्नैक चाहने वालों के लिए एक पौष्टिक विकल्प हैं। नौ अनाजों की अच्छाइयों के साथ अपने स्नैकिंग अनुभव को बेहतर बनाएँ - स्वाद का मज़ा लें, पोषण को अपनाएँ!",
                  },
                ],
              },

              {
                id: 5,
                image_Url: LittleMilletCookie1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: LittleMilletCookie1 },
                  { smallImageId: 2, smallImageUrl: LittleMilletCookie2 },
                  { smallImageId: 3, smallImageUrl: LittleMilletCookie3 },
                ],
                content: "छोटे बाजरा कुकीज़   ",
                oldPrice: "70.00",
                newPrice: " 50.00",
                productDetails: {
                  manufactureTitle: "निर्माता/विपणक",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "वापसी नीति",
                  returnPolicy: "वापसी योग्य नहीं",
                  specialityTitle: "स्पेशलिटी",
                  specialityDesc1: "जीरो मैदा | जीरो शुगर",
                  specialityDesc2: "0% ट्रांसफैट | 0% योजक | 0% परिरक्षक ",
                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
                productDescription: [
                  {
                    proDescId: 1,
                    proDesc:
                      "हमारे लिटिल मिलेट कुकीज़ के स्वास्थ्यवर्धक आकर्षण का आनंद लें - एक शानदार रचना जिसे सावधानी से बनाया गया है। लिटिल मिलेट की अच्छाई में खुद को डुबोएँ, क्योंकि ये कुकीज़ विशेष रूप से इस पौष्टिक अनाज से बनाई जाती हैं, जो अपने असाधारण स्वास्थ्य लाभों के लिए जानी जाती है।",
                  },
                  {
                    proDescId: 2,
                    proDesc:
                      "हमारी लिटिल मिलेट कुकीज़ स्वाद और पोषण का एक अनूठा मिश्रण प्रदान करती हैं, जो एक कुरकुरा बनावट प्रदान करती हैं जो आपको और अधिक खाने के लिए तरस जाएगी। आयरन, फाइबर और बी-विटामिन जैसे आवश्यक पोषक तत्वों से भरपूर, ये कुकीज़ एक अपराध-मुक्त भोग हैं जो आपके समग्र कल्याण में योगदान देता है।         ",
                  },
                  {
                    proDescId: 3,
                    proDesc:
                      "अपने नाश्ते के समय को छोटे बाजरे की शुद्ध अच्छाई के साथ बढ़ाएँ, जो हर स्वादिष्ट निवाले में समाहित है। ये कुकीज़ इस पौष्टिक अनाज की पौष्टिक समृद्धि को अपनाने का एक स्वादिष्ट तरीका है। हमारे छोटे बाजरे की कुकीज़ के साथ अपराध-मुक्त भोग का आनंद लें - हर मुंह में पानी लाने वाले व्यंजन में स्वाद और स्वास्थ्य का सही संतुलन! ",
                  },
                ],
              },

              {
                id: 6,
                image_Url: PowerBarMillet1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: PowerBarMillet1 },
                  { smallImageId: 2, smallImageUrl: PowerBarMillet2 },
                  { smallImageId: 3, smallImageUrl: PowerBarMillet3 },
                ],
                content: " पावर बार | बाजरा चिक्की ",
                newPrice: " 12.00",
                productDetails: {
                  manufactureTitle: "निर्माता/विपणक",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "वापसी नीति",
                  returnPolicy: "वापसी योग्य नहीं",
                  specialityTitle: "स्पेशलिटी",
                  specialityDesc1: "जीरो मैदा | जीरो शुगर",
                  specialityDesc2: "0% ट्रांसफैट | 0% योजक | 0% परिरक्षक ",
                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
              },
            ],
          },

          cart: {
            emptyText: "आपकी गाड़ी खाली है ",
            shopMoreText:
              "हमारे पास आपकी ज़रूरत की सभी दवाइयां और स्वास्थ्य देखभाल उत्पाद उपलब्ध हैं।",
            goToShopPageBtn: "शॉप पेज पर जाएं",
          },
        },
      },

      ar: {
        translation: {
          common: {
            enquiryBtn: "استفسر الان",
            associates: "بمشاركة",
            contactBtn: "اتصل بنا",
            submitBtn: "يُقدِّم",
            knownBtn: " معروف أكثر,",
            viewBtn: "عرض المزيد",

            homeBtn: "انتقل إلى الصفحة الرئيسية",
            thankYou: "شكرًا لك!",
            getBack: "للاتصال بنا، سوف نعود إليك قريبا...",
          },

          headerAndFooter: {
            home: "الصفحة الرئيسية",
            programs: "البرامج",
            diabetes: "السكري",
            dyslipedimia: "دسليبيدميا",
            pcod: "PCOD",
            obesity: "بدانة",
            psoriasis: "صدفية",
            antiaging: "مكافحة الشيخوخة",
            nafld: "NAFLD",
            shop: "محل",
            whatwedo: " ما نقوم به",
            blog: "مدونة",
            cart: "عربة التسوق",
            contactus: "اتصل بنا",
            ndhealthDesc:
              "استكشف برامج ND Health.AI لمرض السكري، واضطراب شحوم الدم، ومتلازمة تكيس المبايض. رعاية شخصية لرحلتك الصحية. ابدأ بالتحول اليوم",
            quickLinks: "روابط سريعة",
            ourPrograms: "برامجنا",
            otherLinks: "روابط أخرى",
            privacy: "خصوصية ",
            terms: "شروط",
            shipping: "شحن",
            cancellation: "إلغاء",
            association: "بمشاركة",
            followUs: "تابعنا",
          },

          shop: {
            coming: "نحن قادمون قريبا ",
            tuned: "ابقوا متابعين",
            homeBtn: "انتقل إلى الصفحة الرئيسية",
            formPlaceholder: "البحث عن الأدوية والمنتجات الصحية",
            categoryProduct: "البائع الساخن",
            quickView: "نظرة سريعة",
            productDesc: "وصف المنتج",
            selectQty: "حدد الكمية",
            qtyEmpty: "لا يمكن أن تكون الكمية فارغة",
            item: "غرض",
            items: "أغراض",
            addToCartBtn: "أضف إلى السلة",
            viewCart: "عرض عربة التسوق",
            forOrder: {
              orderText: "للطلب الرجاء الاتصال على هذا الرقم : ",
              orderNumber: " 17888 89776 91 + ",
            },

            shopData: [
              {
                id: 1,
                image_Url: BakedCrispsIN1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: BakedCrispsIN1 },
                  { smallImageId: 2, smallImageUrl: BakedCrispsIN2 },
                  { smallImageId: 3, smallImageUrl: BakedCrispsIN3 },
                ],
                content: "رقائق البطاطس المخبوزة | طماطم هندية ",
                newPrice: " 20.00",
                productDetails: {
                  manufactureTitle: "الشركة المصنعة / المسوق",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "سياسة العائدات",
                  returnPolicy: "غير قابلة للإرجاع",

                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },

                productDescription: [
                  {
                    proDescId: 1,
                    proDesc:
                      "متع براعم التذوق لديك بالمذاق المقرمش للدخن متعدد الحبوب، الذي يقدم مزيجًا متناغمًا من النكهات والقوام في كل قضمة. تضيف التوابل المستخرجة من الطماطم الهندية التقليدية لمسة من المذاق الأصيل، مما يخلق تجربة تناول وجبات خفيفة لذيذة وغنية ثقافيًا.  ",
                  },
                  {
                    proDescId: 2,
                    proDesc:
                      "بينما تتذوق كل قطعة مقرمشة، كن مطمئنًا أنك تستمتع بوجبة خفيفة خالية من التحسينات الاصطناعية. إن رقائق البطاطس المخبوزة لدينا هي متعة خالية من الذنب، وتوفر علاجًا مرضيًا ولذيذًا دون المساس بالتزامك بالخيرات الطبيعية. ارفع مستوى تجربتك في تناول الوجبات الخفيفة مع رقائق البطاطس المخبوزة الصحية والمملوءة بالتوابل - مزيج من التقاليد والذوق!.         ",
                  },
                ],
              },

              {
                id: 2,
                image_Url: BakedCrispsH1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: BakedCrispsH1 },
                  { smallImageId: 2, smallImageUrl: BakedCrispsH2 },
                  { smallImageId: 3, smallImageUrl: BakedCrispsH3 },
                ],
                content: "رقائق البطاطس المخبوزة | الصفحة الرئيسية ماسالا ",
                newPrice: " 20.00",
                productDetails: {
                  manufactureTitle: "الشركة المصنعة / المسوق",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "سياسة العائدات",
                  returnPolicy: "غير قابلة للإرجاع",

                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
              },
              {
                id: 3,
                image_Url: FoxtailMillet1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: FoxtailMillet1 },
                  { smallImageId: 2, smallImageUrl: FoxtailMillet2 },
                  { smallImageId: 3, smallImageUrl: FoxtailMillet3 },
                ],
                content: "بسكويت الدخن الثعلبي ",
                oldPrice: "70.00",
                newPrice: " 50.00",
                productDetails: {
                  manufactureTitle: "الشركة المصنعة / المسوق",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "سياسة العائدات",
                  returnPolicy: "غير قابلة للإرجاع",
                  specialityTitle: "تخصص",
                  specialityDesc1: "صفر مايدا | صفر سكر",
                  specialityDesc2: "0% ترانس فات | 0% إضافات | 0% مواد حافظة ",
                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
                productDescription: [
                  {
                    proDescId: 1,
                    proDesc:
                      "تذوق السحر الصحي لبسكويت الدخن الصغير الخاص بنا - وهو ابتكار مبهج تم إعداده بعناية. انغمس في فوائد الدخن الصغير، حيث يتم إعداد هذه البسكويت حصريًا من هذه الحبوب المغذية والمعروفة بفوائدها الصحية الاستثنائية.",
                  },
                  {
                    proDescId: 2,
                    proDesc:
                      "تقدم بسكويت الدخن الصغير لدينا مزيجًا فريدًا من المذاق والتغذية، مما يوفر ملمسًا مقرمشًا سيتركك متشوقًا للمزيد. غنية بالعناصر الغذائية الأساسية مثل الحديد والألياف وفيتامينات ب، تعد هذه الكوكيز بمثابة متعة خالية من الشعور بالذنب وتساهم في رفاهيتك بشكل عام.",
                  },
                  {
                    proDescId: 3,
                    proDesc:
                      "ارفع مستوى وقت تناول الوجبات الخفيفة مع الطعم النقي للدخن الصغير، الذي يتم الاحتفال به في كل قضمة مبهجة. تعتبر ملفات تعريف الارتباط هذه طريقة لذيذة لاحتضان الثراء الغذائي لهذه الحبوب الصحية. اختبر متعة الاستمتاع بدون الشعور بالذنب مع بسكويت الدخن الصغير - التوازن المثالي بين النكهة والصحة في كل علاج شهي!",
                  },
                ],
              },

              {
                id: 4,
                image_Url: NavaGrain1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: NavaGrain1 },
                  { smallImageId: 2, smallImageUrl: NavaGrain2 },
                  { smallImageId: 3, smallImageUrl: NavaGrain3 },
                ],
                content: "كوكيز حبوب نافا ",
                oldPrice: "70.00",
                newPrice: " 50.00",
                productDetails: {
                  manufactureTitle: "الشركة المصنعة / المسوق",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "سياسة العائدات",
                  returnPolicy: "غير قابلة للإرجاع",
                  specialityTitle: "تخصص",
                  specialityDesc1: "صفر مايدا | صفر سكر",
                  specialityDesc2: "0% ترانس فات | 0% إضافات | 0% مواد حافظة ",
                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
                productDescription: [
                  {
                    proDescId: 1,
                    proDesc:
                      "انغمس في الخيرات الصحية لبسكويت نافا جرين - وهي علاج لذيذ مصنوع من مزيج فريد من تسع حبوب صحية. مصنوعة من الدخن الإصبعي، والدخن الكودو، والدخن اللؤلؤي، والدخن البارنيارد، والدخن الصغير، والدخن الثعلبي، والدخن البروسو، والذرة الرفيعة، ودقيق القمح الكامل، تقدم ملفات تعريف الارتباط هذه سيمفونية من النكهات والفوائد الغذائية. ",
                  },
                  {
                    proDescId: 2,
                    proDesc:
                      "كل قضمة مقرمشة هي احتفال بالتنوع الغني للحبوب، مما يوفر مزيجًا متناغمًا من المذاق والصحة. تعد هذه البسكويت مصدرًا للعناصر الغذائية الأساسية مثل الألياف والفيتامينات والمعادن، مما يساهم في الحصول على وجبة خفيفة متوازنة ومغذية.       ",
                  },
                  {
                    proDescId: 3,
                    proDesc:
                      "إن ملفات تعريف الارتباط Nava Grain ليست مجرد علاج لذوقك؛ فهي خيار صحي لأولئك الذين يبحثون عن وجبة خفيفة لذيذة ومليئة بالعناصر الغذائية. ارفع مستوى تجربة تناول الوجبات الخفيفة لديك مع جودة تسع حبات - تذوق النكهة واحتضن العناصر الغذائية!",
                  },
                ],
              },

              {
                id: 5,
                image_Url: LittleMilletCookie1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: LittleMilletCookie1 },
                  { smallImageId: 2, smallImageUrl: LittleMilletCookie2 },
                  { smallImageId: 3, smallImageUrl: LittleMilletCookie3 },
                ],
                content: "بسكويت الدخن الصغير   ",
                oldPrice: "70.00",
                newPrice: " 50.00",
                productDetails: {
                  manufactureTitle: "الشركة المصنعة / المسوق",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "سياسة العائدات",
                  returnPolicy: "غير قابلة للإرجاع",
                  specialityTitle: "تخصص",
                  specialityDesc1: "صفر مايدا | صفر سكر",
                  specialityDesc2: "0% ترانس فات | 0% إضافات | 0% مواد حافظة ",

                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
                productDescription: [
                  {
                    proDescId: 1,
                    proDesc:
                      "تذوق السحر الصحي لبسكويت الدخن الصغير الخاص بنا - وهو ابتكار مبهج تم إعداده بعناية. انغمس في فوائد الدخن الصغير، حيث يتم إعداد هذه البسكويت حصريًا من هذه الحبوب المغذية والمعروفة بفوائدها الصحية الاستثنائية. ",
                  },
                  {
                    proDescId: 2,
                    proDesc:
                      "تقدم بسكويت الدخن الصغير لدينا مزيجًا فريدًا من المذاق والتغذية، مما يوفر ملمسًا مقرمشًا سيتركك متشوقًا للمزيد. غنية بالعناصر الغذائية الأساسية مثل الحديد والألياف وفيتامينات ب، تعد هذه الكوكيز بمثابة متعة خالية من الشعور بالذنب وتساهم في رفاهيتك بشكل عام.       ",
                  },
                  {
                    proDescId: 3,
                    proDesc:
                      "ارفع مستوى وقت تناول الوجبات الخفيفة مع الطعم النقي للدخن الصغير، الذي يتم الاحتفال به في كل قضمة مبهجة. تعتبر ملفات تعريف الارتباط هذه طريقة لذيذة لاحتضان الثراء الغذائي لهذه الحبوب الصحية. اختبر متعة الاستمتاع بدون الشعور بالذنب مع بسكويت الدخن الصغير - التوازن المثالي بين النكهة والصحة في كل علاج شهي!",
                  },
                ],
              },

              {
                id: 6,
                image_Url: PowerBarMillet1,
                smallImages_Url: [
                  { smallImageId: 1, smallImageUrl: PowerBarMillet1 },
                  { smallImageId: 2, smallImageUrl: PowerBarMillet2 },
                  { smallImageId: 3, smallImageUrl: PowerBarMillet3 },
                ],
                content: " شريط الطاقة | الدخن تشيكي ",
                newPrice: " 12.00",
                productDetails: {
                  manufactureTitle: "الشركة المصنعة / المسوق",
                  manufacture: "Vasudha foods",
                  manufactureFrom: "From the house of Hare Krishna",

                  returnPolicyTitle: "سياسة العائدات",
                  returnPolicy: "غير قابلة للإرجاع",
                  specialityTitle: "تخصص",
                  specialityDesc1: "صفر مايدا | صفر سكر",
                  specialityDesc2: "0% ترانس فات | 0% إضافات | 0% مواد حافظة ",

                  // expireTitle: "Expires on or after",
                  // expire: "Dec-24",
                },
              },
            ],
          },
          cart: {
            emptyText: "عربة التسوق فارغة ",
            shopMoreText:
              "لدينا جميع الأدوية ومنتجات الرعاية الصحية التي تحتاجها.",
            goToShopPageBtn: "انتقل إلى صفحة المتجر",
          },
        },
      },
    },

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
