import { Link } from "react-router-dom";
import MetaInfo from "../../../Meta/Meta-Info";

const ShopShipping = () => {
  return (
    <section className="border-[1rem] sm:border-[3rem] border-lightBlue p-4 sm:p-8 flex flex-col gap-10  md:text-base text-sm">
      {/* Meta PageTitle and PageDescription */}

      <MetaInfo
        pageTitle={"NDHealth Shipping Information - Fast and Reliable Delivery"}
        pageDescription={
          "Explore NDHealth.ai's shipping policies, delivery times, and costs. Enjoy fast, reliable shipping to get your products delivered quickly and efficiently"
        }
        pageKeywords={
          ("NDHealth Shipping",
          "NDHealth Delivery",
          "NDHealth Order Tracking",
          "NDHealth Shipping Rates",
          "NDHealth International Shipping")
        }
      />

      <h1 className="lg:text-5xl md:text-2xl text-xl font-medium text-center sm:py-5 font-poppins">
        SHIPPING POLICY
      </h1>

      {/****    How does the delivery process work?    ******/}

      <div className="md:text-base text-sm">
        <h3 className="md:text-2xl text-xl pb-5 font-medium">
          How does the delivery process work?
        </h3>
        <ul className="list-disc md:pl-7 pl-3">
          <li>
            Once our system processes your order, your products are inspected
            thoroughly to ensure they are in a perfect condition.
          </li>
          <li className="py-3">
            After they pass through the final round of quality check, they are
            packed and handed over to our trusted delivery partner.
          </li>
          <li>
            Our delivery partners then bring the package to you at the earliest
            possiblity. In case, they are unable to reach your provided address
            or at a suitable time, they will contact you to resolve the issue.
          </li>
        </ul>
      </div>

      {/****    How are items packaged?    ******/}

      <div className="md:text-base text-sm">
        <h3 className="md:text-2xl text-xl pb-5 font-medium">
          How are items packaged?
        </h3>
        <p className="md:leading-7">
          We are committed to sustainability in our packaging while ensuring the
          safety of the orders dispatched. Our NDhealth.shop cardboard outer box
          is eco friendly and made from 100% recycled paper. Where feasible, we
          use paper-fillers instead of bubble wrap, and this is a process of
          continuous improvement for us. We are committed to further
          substantially reducing plastic in our packaging and are constantly
          innovating new solutions given the nature of our category.
        </p>
      </div>

      {/****    What is the range of locations to which Ndhealth.shop ships their products?    ******/}

      <div className="md:text-base text-sm">
        <h3 className="md:text-2xl text-xl pb-5 font-medium">
          What is the range of locations to which Ndhealth.shop ships their
          products?
        </h3>
        <p>Ndhealth.shop does not ship internationally currently.</p>
      </div>

      {/****    My order has been shipped. Now how can I track it?    ******/}

      <div className="md:text-base text-sm">
        <h3 className="md:text-2xl text-xl pb-5 font-medium">
          My order has been shipped. Now how can I track it?
        </h3>
        <p className="md:leading-7">
          After placing your order, you will receive a confirmation message on
          your registered email and phone number. As we prepare and ship your
          order within 24-48 hours of the confirmation, you can then check the
          status of your package through the 'Track' button on the 'My Orders'
          section. You can also choose to receive your order updates on
          WhatsApp.
        </p>
      </div>

      {/****    What is the estimated delivery time?    ******/}

      <div className="md:text-base text-sm">
        <h3 className="md:text-2xl text-xl pb-5 font-medium">
          What is the estimated delivery time?
        </h3>
        <p className="pb-3">
          We usually dispatch most orders within 1-4 business days (excluding
          Sundays and public holidays)
        </p>
        <ul className="list-disc md:pl-7 pl-3">
          <li>
            Though, we keep 75% of our catalogue in our inventory, certain
            products need to be sourced directly from the brand itself so that
            we can live up to our promise of providing fresh, non-expired
            products.
          </li>
          <li className="py-3">
            While we are trying our best to avoid this situation, these products
            might delay your order.
          </li>
          <li>
            If you are ordering our products from a Mega Sale event, dispatches
            may be a bit delayed due to increased volumes. We will target to
            dispatch all orders within 5 days of order date.
          </li>
        </ul>
      </div>

      {/****  My order will be shipped in multiple shipments? What does this mean?    ******/}

      <div className="md:text-base text-sm">
        <h3 className="md:text-2xl text-xl pb-5 font-medium">
          My order will be shipped in multiple shipments? What does this mean?
        </h3>
        <p className="md:leading-7">
          Don’t worry! This is a completely normal situation. This just means
          that different parts of your order may have simply been shipped from
          our different warehouse locations. Rest assured, you will only have to
          pay the shipping/COD charge if applicable, on the first package you
          receive.
        </p>
      </div>

      {/****   Are there any shipping charges applicable on my order?    ******/}

      <div className="md:text-base text-sm">
        <h3 className="md:text-2xl text-xl pb-5 font-medium">
          Are there any shipping charges applicable on my order?
        </h3>

        <ul className="list-disc md:pl-7 pl-3">
          <li>We have standard shipping charges for most addresses.</li>
          <li className="py-3">
            The shipping charges currently are as Free shipping on orders above
            ₹499 for every user.
          </li>
          <li>
            We are currently not accepting Cash On Delivery orders below ₹1499.
          </li>
        </ul>
      </div>

      {/**** Does Ndhealth.shop ship outside India?    ******/}

      <div className="md:text-base text-sm">
        <h3 className="md:text-2xl text-xl pb-5 font-medium">
          Does Ndhealth.shop ship outside India?
        </h3>
        <p className="md:leading-7">
          Ndhealth.shop does not ship internationally currently.
        </p>
      </div>
    </section>
  );
};

export default ShopShipping;
