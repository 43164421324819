import ShopFooter from "../ShopFooter";
import ShopHeader from "../ShopHeader";

const ShopLayout = ({ children }) => {
  return (
    <div className="font-poppins">
      <ShopHeader />
      {children}
      <ShopFooter />
    </div>
  );
};

export default ShopLayout;
